
import React, { useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InternalNavigator from '@modules/Dashboard/components/InternalNavigator';
import MainContentSection from '@components/ui/MainContentSection';
import { Navigate, Route, Routes } from 'react-router-dom';
import IDCardsSearch from './components/IDCardsSearch.component';
import IDCardInformation from './components/IDCardInformation.component';
/**
 * IDCards module component
 * @param {object} props - Component props
 * @returns {JSX.Element}
 */
const IDCardsModule = (): JSX.Element => {
    const [ viewTitle, setViewTitle ] = useState<string>("Credencialización");

    const subNavigatorOptions = [
        {
            path: "search",
            icon: faSearch,
            classes: "bg-custom-purple",
            label: "Search"
        }
    ]
    return <>
        <InternalNavigator options={subNavigatorOptions} />
        <MainContentSection title={viewTitle}>
            <Routes>
                <Route index element={<Navigate to="search" replace />} />
                <Route path="/search" element={<IDCardsSearch setViewTitle={setViewTitle} />} />
                <Route path="/information" element={<IDCardInformation setViewTitle={setViewTitle} />} />
            </Routes>
        </MainContentSection>
    </>;
};

export default IDCardsModule;
