import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ActionInProcessProvider } from './context/ActionInProcessContext';
import reportWebVitals from './reportWebVitals';
import App from './modules/App';
import ActionInProcessWindow from '@components/ui/ActionInProcessWindow';
import { AuthProvider } from '@context/AuthContext';
import packageJson from '../package.json';

import './index.css';

// Inyectar la versión en la meta tag
document.addEventListener('DOMContentLoaded', () => {
  const versionMetaTag = document.querySelector('meta[name="app-version"]');
  if (versionMetaTag) {
    versionMetaTag.setAttribute('content', packageJson.version);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <ActionInProcessProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
      <ActionInProcessWindow />
    </ActionInProcessProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
