
const FileIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Uploaded to svgrepo.com"
        viewBox="0 0 32 32"
        {...props}
    >
        <style>{".flatshadows_vijf{fill:#8d9999}"}</style>
        <path
            d="M28 32H4V6l6-6h18z"
            style={{
                fill: "#e1e5e5",
            }}
        />
        <path
            d="m4 6 2 2h6V2l-2-2z"
            style={{
                fill: "#c4cccc",
            }}
        />
        <path
            d="M10 0v6H4z"
            style={{
                fill: "#fdffff",
            }}
        />
        <path
            d="M13 13h6v1h-6zM13 15h6v1h-6zM13 17h6v1h-6z"
            className="flatshadows_vijf"
        />
        <path
            d="M13 13h6v1h-6zM13 15h6v1h-6zM13 17h6v1h-6z"
            className="flatshadows_vijf"
        />
    </svg>
)
export default FileIcon