import { useState, useImperativeHandle, forwardRef, useRef } from "react";
import InputCardMakerHandle from "../interfaces/InputCardMakerHandle";
import InputCardMakerProps from "../interfaces/InputCardMakerProps";

// Asegúrate de que InputCardMakerProps incluya todas las propiedades necesarias
interface InputXYCardMakerProps extends InputCardMakerProps {
    withX?: boolean,
    withY?: boolean,
    max?: number,
    min?: number,
    readOnly?: boolean
};

const InputXYCardMaker = forwardRef<InputCardMakerHandle, InputXYCardMakerProps>((props, ref) => {
    const { label, name, type, required, observer, withX, withY, max, min, readOnly } = props;
    const [value, setValue] = useState<string>(props.value || "");
    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const [isValid, setIsValid] = useState<boolean>(true);
    const inputRef = useRef<HTMLInputElement>(null);

    const validateValue = (valueToValidate: string): boolean => {
        if (required && !valueToValidate) {
            return false;
        }
        switch (type) {
            case 'number':
                return /^\d+$/.test(valueToValidate);
            case 'email':
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valueToValidate);
            case 'phone':
                return /^\+?\d{10,15}$/.test(valueToValidate);
            case 'alphabetic':
                return /^[a-zA-Z\s]*$/.test(valueToValidate);
            case 'curp':
                return /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/.test(valueToValidate.toUpperCase());
            case 'text':
            default:
                return true;
        }
    };

    const validateCoordinate = (valueToValidate: string): boolean => {
        const numberValue = parseInt(valueToValidate, 10);
        return !isNaN(numberValue) && numberValue >= (min ? min : -999) && numberValue <= (max ? max : 999);
    };

    useImperativeHandle(ref, () => ({
        clean() {
            setValue("");
            setX(0);
            setY(0);
            setIsValid(true);
        },
        validate() {
            return validateValue(value) && validateCoordinate(x.toString()) && validateCoordinate(y.toString());
        },
        getInputRef() {
            return inputRef.current;
        },
        get() {
            return { value, x, y };
        }
    }));

    const handleChange = (newValue: string, setValueData: any, target: string) => {

        let currentIsValid = true;
        if (target === "value") {
            currentIsValid = validateValue(newValue);
        } else {
            currentIsValid = validateCoordinate(newValue);
        }

        let output = {
            value,
            x,
            y
        }

        if (target === "value") {
            setValueData(newValue);
            output.value = newValue
        }

        if (target === "x") {
            let xValue = parseInt(newValue, 10);
            setX(xValue)
            output.x = xValue
        }

        if (target === "y") {
            let yValue = parseInt(newValue, 10);
            setY(yValue)
            output.y = yValue
        }

        setIsValid(currentIsValid);
        if (observer) {
            observer(output);
        }
    };

    return (
        <div className="formItem">
            {label && (
                <div className="label">
                    {!isValid && <p>*</p>} <label htmlFor={"input-" + name}>{label}</label>
                </div>
            )}
            <div className="input">
                <div className="valueContainer">
                    <input
                        ref={inputRef}
                        name={"input-" + name}
                        id={"input-" + name}
                        type={type === 'alphabetic' || type === 'curp' ? 'text' : type}
                        value={value}
                        onChange={(e) => handleChange(e.target.value, setValue, "value")}
                        readOnly={readOnly}
                    />
                </div>
                {
                    withX && <div className="xContainer">
                        <input
                            className="xInput"
                            type="number"
                            min="-999"
                            max="999"
                            value={x}
                            onChange={(e) => handleChange(e.target.value, setX, "x")}
                        />
                        <input type="range" min={min ? min : "-999"} max={max ? max : "999"} value={x} step="1" className="slider" onChange={(e) => handleChange(e.target.value, setX, "x")}></input>
                    </div>
                }
                {
                    withY && <div className="yContainer">
                        <input
                            type="number"
                            min="-999"
                            max="999"
                            value={y}
                            onChange={(e) => handleChange(e.target.value, setY, "y")}
                        />
                        <input type="range" min={min ? min : "-999"} max={max ? max : "999"} value={y} step="1" className="slider" onChange={(e) => handleChange(e.target.value, setY, "y")}></input>
                    </div>
                }
            </div>
        </div>
    );
});

export default InputXYCardMaker;