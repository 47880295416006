class DefaultIDDataPropModel {
    public value?: string = "";
    public x?: number = 0;
    public y?: number = 0;

    constructor(value = "", x = 0, y = 0){
        this.value = value;
        this.x = x;
        this.y = y;
    }
}

export default DefaultIDDataPropModel;