import React, { useContext } from 'react'

import classnames from 'classnames'
import './style.scss'
import AnimationSuccessCheck from '../Animations/AnimationSuccessCheck'
import AnimationErrorCircle from '../Animations/AnimationErrorCircle'
import AnimatedActionCircle from '../Animations/AnimatedActionCircle'
import SandClockAnimation from '../Animations/SandClockAnimation'
import AnimationDots from '../Animations/AnimationDots'
import ActionInProcessContext from '@context/ActionInProcessContext'

const ActionInProcessWindow = (props) => {
  const { wait, actionMessage, actionType } = useContext(ActionInProcessContext);
  // console.log("ctx", {wait, actionMessage});

  let animation = null;

  switch (actionType) {

    case "success":
      animation = <AnimationSuccessCheck />
      break;

    case "error":
      animation = <AnimationErrorCircle />
      break;

    case "info":
      animation = <AnimatedActionCircle type="info" />
      break;

    case "warning":
      animation = <AnimatedActionCircle type="warning" />
      break;

    case "process":
      animation = <>
      </>
      break;

    case "waiting":
    default:
      animation = <SandClockAnimation />
      break;
  }


  return (
    <div className={classnames("ActionInProcess", actionType === "process" ? "process" : "", wait ? 'show' : 'hide')} >
      {
        actionType === "process" ? <>
          <div className="circled-process-loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          {
            actionMessage && <h3 className="message">{actionMessage}</h3>
          }
        </>
          :
          <>
            <div className="box">
              <div className="section-top">
                <section className="animation">
                  {animation}
                </section>
              </div>
              <div className={classnames("section-bottom", 'text-center', wait)}>

                {
                  wait === "waiting" ?
                    <span className="title">
                      Un momento por favor <AnimationDots />
                    </span>
                    :
                    null
                }

                {
                  actionMessage ?
                    <div className={classnames("message", wait)}>
                      {actionMessage}
                    </div>
                    :
                    <React.Fragment>
                      {'Estamos procesando su solicitud'} <AnimationDots />
                    </React.Fragment>
                }

              </div>
            </div>
          </>
      }


    </div>
  )
}

export default ActionInProcessWindow
