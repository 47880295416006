import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import JsBarcode from 'jsbarcode';
import "./style.css"

interface BarcodeOptions {
    format: string; // "auto" para el valor predeterminado que sería CODE128
    width: number; // Ancho de una sola barra
    height: number; // Altura del código de barras
    displayValue: boolean; // Si se muestra o no el valor textual del código de barras
    fontOptions?: string; // Opciones de estilo para la fuente, como "bold"
    font?: string; // Tipo de fuente para el texto del código de barras
    textAlign?: 'left' | 'center' | 'right'; // Alineación del texto
    textPosition?: 'top' | 'bottom'; // Posición del texto respecto al código de barras
    textMargin?: number; // Margen entre el texto y el código de barras
    fontSize?: number; // Tamaño de fuente del texto
    background?: string; // Color de fondo del código de barras (CSS color)
    lineColor?: string; // Color de las líneas/barras del código de barras (CSS color)
    margin?: number; // Margen alrededor del código de barras
    marginTop?: number; // Margen superior específico
    marginBottom?: number; // Margen inferior específico
    marginLeft?: number; // Margen izquierdo específico
    marginRight?: number; // Margen derecho específico
    valid?: (valid: boolean) => void; // Función de callback que se llama después de la validación del código de barras
}

const defaultBarcodeOptions: BarcodeOptions = {
    width: 2,
    height: 40,
    displayValue: false,
    format: "CODE128",
    textMargin: 0,
    margin: 60
}

interface BarcodeCanvasProps {
    id?: string,
    onRender?: (base64Image: string) => void,
    options?: BarcodeOptions,
    value: string,
    downloadable?: boolean
}

const BarcodeCanvas = forwardRef(({ onRender, id, value, downloadable, options = defaultBarcodeOptions }: BarcodeCanvasProps, ref) => {
    const canvasRef: any = useRef(null);

    const handleClickAction = () => {
        if (!canvasRef.current || !downloadable) return;
        const imageDataUrl = canvasRef.current.toDataURL('image/jpeg');  // Obtiene la imagen en formato JPEG
        const link = document.createElement('a');  // Crea un enlace temporal
        link.href = imageDataUrl;  // Establece el HREF del enlace como la imagen
        link.download = `barcode-${value}.jpeg`;  // Establece el atributo de descarga con un nombre de archivo
        document.body.appendChild(link);  // Agrega el enlace al cuerpo del documento
        link.click();  // Simula un clic en el enlace para iniciar la descarga
        document.body.removeChild(link);  // Elimina el enlace del documento
    };

    // Esta función convierte el contenido del canvas en una imagen base64 en alta definición
    const handleOnRender = () => {
        if (!canvasRef.current) return null;

        const canvas: any = canvasRef.current;
        // Ajustamos la densidad de pixeles para alta definición
        const scaleFactor = window.devicePixelRatio || 1;
        const width = canvas.width;
        const height = canvas.height;

        const hdCanvas = document.createElement('canvas');
        hdCanvas.width = width * scaleFactor;
        hdCanvas.height = height * scaleFactor;
        const hdContext = hdCanvas.getContext('2d');
        if(hdContext){
            hdContext.scale(scaleFactor, scaleFactor);
            hdContext.drawImage(canvas, 0, 0);
        }

        // Convertimos el canvas HD a una imagen en formato JPEG base64
        return hdCanvas.toDataURL('image/jpeg');
    };

    useEffect(() => {
        if (canvasRef.current) {
            JsBarcode(canvasRef.current, value, { ...defaultBarcodeOptions, ...options, width: 20, height: 400 });
            const renderImage: string | null = handleOnRender();
            if(renderImage && onRender){
                onRender(renderImage)
            }
        }
    }, [value, options, onRender]);

    useImperativeHandle(ref, () => ({
        // Esta función es accesible desde fuera del componente y retorna la imagen en base64
        getImage: () => handleOnRender()
    }));

    return <div className={["codebarCanvasContainer", downloadable && "downloadable"].join(" ")} onClick={handleClickAction}><canvas id={id} ref={canvasRef} /></div>;
});

export default BarcodeCanvas;
