import { Button } from '@components/form/Button';
import { faClose, faFlag} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./style.css";
import { useEffect } from 'react';

const Modal = (props: any) => {
    const { footer, icon, title, handleClose, hideOnEscape, withoutCloseButton, noHideOnBackdrop, modalFull } = props;

    const rejectClick = (event: any) => {
        event.stopPropagation();
    }

    useEffect(() => {
        const keyListener = (event: KeyboardEvent) => {
            if (event.key === "Escape" && hideOnEscape && handleClose) {
                handleClose()
            }
        }

        document.addEventListener("keydown", keyListener , false);

        return () => {
            document.removeEventListener("keydown", keyListener, false);
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return <div className='modal w-screen h-screen bg-gray-800 bg-opacity-70 burred absolute top-0 left-0 z-50 flex justify-center items-start p-4' onClick={()=> !noHideOnBackdrop && handleClose}>
        <div className={`modal-window bg-slate-800 ${modalFull ? "w-full px-2" : "w-3/4"} mt-4`} onClick={rejectClick}>
            <div className="modal-header p-4 h-16 flex flex-row items-center justify-between">
                <div className="title-container flex flex-row items-center justify-start">
                    <FontAwesomeIcon icon={icon ? icon : faFlag} className="text-white text-3xl mr-4" />
                    <span className="text-white text-xl">{title}</span>
                </div>
                {
                    !withoutCloseButton && 
                        <Button className="w-10 h-10 flex items-center justify-center p-0 text-gray-200 hover:text-gray-100, active:text-gray-500" onClick={handleClose}>
                            <FontAwesomeIcon icon={faClose} className="text-3xl p-0" />
                        </Button>
                }
            </div>
            <div className="modal-content w-full flex items-center justify-center text-white overflow-auto" style={{minHeight: "300px", maxHeight: "90vh"}}>{props.children}</div>
            <div className="modal-footer w-full flex items-center justify-center">
                {
                    footer
                }
            </div>
        </div>
    </div>
}

export default Modal