import { useCallback, useRef, useState } from "react";
import CloudUploadIcon from './CloudUploadIcon';
import FileIcon from "./FileIcon";
import "./style.css";
import CanvasMaskedPhotoComponent from "./CircularMaskedImage";

interface PictureIdComponentProps {
    image?: string;
    darkMode?: boolean;
    ajustMode?: boolean;
    onAlert?: (message: string) => void;
    onCancel?: () => void;
    onConfirm?: (image?: string | undefined) => void;
}


const PictureIdComponent = ({ darkMode, ajustMode, image, onAlert, onCancel, onConfirm }: PictureIdComponentProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [imageFile, setImageFile] = useState<File | undefined | string>();
    const [isDragOver, setIsDragOver] = useState(false);

    const handleFile = useCallback((file: File) => {
        if (file.type.startsWith('image/')) {
            setImageFile(file);
        } else {
            onAlert && onAlert("Please upload an image file.");
        }
    }, [onAlert]);

    const handleImageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 1) {
            onAlert && onAlert("Please upload only one file at a time.");
        } else if (files && files[0]) {
            handleFile(files[0]);
        }
    }, [handleFile, onAlert]);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!isDragOver) setIsDragOver(true);
    }, [isDragOver]);

    const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!isDragOver) setIsDragOver(true);
    }, [isDragOver]);

    const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (isDragOver) setIsDragOver(false);
    }, [isDragOver]);

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false);
        const files = event.dataTransfer.files;
        if (files.length > 1) {
            onAlert && onAlert("Por favor ingresa solo un archivo de imagen.");
        } else if (files[0] && files[0].type.startsWith('image/')) {
            handleFile(files[0]);
        } else {
            onAlert && onAlert("Ingresa por favor una imagen.");
        }
    }, [onAlert, handleFile]);

    const handleButtonClick = () => {
        inputRef.current?.click();
    };

    const handleConfirmImage = (image: string) => {
        // console.log("image", image);
        setImageFile(undefined)
        if (onConfirm) {
            onConfirm(image);
        }
    }

    const handleOnCancel = () => {
        setImageFile(undefined);
        if (onCancel) {
            console.log("trigered");
            console.log(onCancel);
            
            onCancel();
        }
    }

    if(ajustMode && (imageFile || image)){
        return <CanvasMaskedPhotoComponent darkMode image={image} base64Image onConfirm={handleConfirmImage} onCancel={handleOnCancel} rounded/>
    }

    return <>
        {
            imageFile ? <>
                <CanvasMaskedPhotoComponent darkMode image={imageFile} onConfirm={handleConfirmImage} onCancel={handleOnCancel} rounded/>
            </> : <div className="PictureIDComponentContainer">
                <div className={`PictureIDComponent ${isDragOver ? 'drag-over' : ''}` + (darkMode && " dark")}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onClick={handleButtonClick}>
                    <div className="inputSelection">
                        {
                            isDragOver ? <FileIcon className="input-icon" /> : <CloudUploadIcon className="input-icon" />
                        }
                        <input type="file" accept="image/*" onChange={handleImageChange} ref={inputRef} />
                        {isDragOver ? <span>Suelta tu archivo aquí</span> : <p className="legend"><span>Selecciona tu Imagen</span> <span>o</span> <span>haz clic aquí para seleccionarla</span></p>}
                    </div>
                </div>
                <button className="button cancel" onClick={handleOnCancel}>Cancelar</button>
            </div>
        }
    </>
};

export default PictureIdComponent;
