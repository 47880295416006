import { createContext, useState } from "react";

const ActionInProcessContext = createContext<any>({});

export const ActionInProcessProvider = (props: any) => {
    const [actionMessage, setActionMessage] = useState<string>("");
    const [actionType, setActionType] = useState<string>("process");
    const [wait, setActionWait] = useState<boolean>(false);

    const showWait = (message: string) => {
        setActionMessage(message);
        setActionWait(true);
    };
    const hideWait = () => setActionWait(false);

    const value = {
        actionMessage,
        setActionMessage,
        actionType,
        setActionType,
        wait,
        setActionWait,
        showWait,
        hideWait
    };
    return <ActionInProcessContext.Provider value={value}>
        {props.children}
    </ActionInProcessContext.Provider>
}

export default ActionInProcessContext;