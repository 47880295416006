import useAuth from '@hooks/useAuth';
import useAxios from '@hooks/useAxios';

interface IdCard {
    card_id: string,
    nombre: string,
    tipo: string,
    matricula: string,
    carrera: string,
    curp: string,
    telefono: string,
    correo: string,
    direccion: string,
    ciudad: string,
    estado: string,
    cp: string,
    telefono_emergencia: string,
    nombre_emergencia: string,
    nss: string,
    sangre: string,
    donador: string,
    user_id: string,
    status: string
}

interface SearchResponse {
    data: IdCard[];
    status: number;
}

interface StoreResponse {
    data: any;
    status: number;
}

const ServerURL = "https://myID.upn164.edu.mx/api";

const useIdCardService = () => {
    const { auth } = useAuth();
    const { session } = auth;
    const { instance, getHeaderInstance } = useAxios(ServerURL);

    const search = async (
        filter: string | null = "",
        search: string | null = "",
        page: number = 1,
        pageSize: number = 10,
        sortBy: string | null = 'card_id',
        sortOrder: 'asc' | 'desc' = 'asc'
    ): Promise<SearchResponse> => {
        const token: string = session.token;
        const headers = getHeaderInstance({
            Authorization: 'Bearer ' + token
        });
        const config = {
            headers,
            timeout: 1000 * 25, // Wait for 25 seconds
            validateStatus: () => true
        };

        const searchQuery = filter && search ? `filter[${filter}]=${search}` : "";
        const sortQuery = sortBy && sortOrder ? `sort[${sortBy}]=${sortOrder}` : "";
        const query = [searchQuery, sortQuery].filter(Boolean).join('&');
        const serverURL = `idcards/search?page[size]=${pageSize}&page[number]=${page}&${query}`;
                
        try {
            const response = await instance.get(serverURL, config);
            const { data, status } = response;
            if (status === 200) {
                return { data, status };
            }
            throw new Error(`Error: ${status}`);
        } catch (err) {
            console.error("Error during search", err);
            throw err;
        }
    };

    const get = (index: string) => {
        const token: string = session.token;

        return new Promise((resolve, reject) => {
            const headers = getHeaderInstance({
                Authorization: 'Bearer ' + token
            });
            const config = {
                headers,
                validateStatus: () => true
            }

            const serverURL = `idcards/${index}`;

            instance.get(serverURL, config).then((response: any) => {
                const { data, status } = response;
                if (status === 200) {
                    resolve({ data, status })
                    return
                }
                reject({ data, status })
            }).catch((err: any) => {
                reject(err)
            });
        })
    }

    const getWithPhoto = (index: string) => {
        const token: string = session.token;

        return new Promise((resolve, reject) => {
            const headers = getHeaderInstance({
                Authorization: 'Bearer ' + token
            });
            const config = {
                headers,
                validateStatus: () => true
            }

            const serverURL = `idcards/withphoto/${index}`;

            instance.get(serverURL, config).then((response: any) => {
                const { data, status } = response;
                if (status === 200) {
                    resolve({ data, status })
                    return
                }
                reject({ data, status })
            }).catch((err: any) => {
                reject(err)
            });
        })
    }

    const storeIdStore = (payload: any): Promise<StoreResponse> => {
        const token: string = session.token;
        return new Promise((resolve, reject) => {
            const headers = getHeaderInstance({
                Authorization: 'Bearer ' + token
            });
            const config: any = {
                headers,
                timeout: 1000 * 25, // Wait for 25 seconds
                validateStatus: () => true
            };

            try {
                instance.post(`idcards`, payload, config).then((response: any) => {
                    const { data, status } = response;
                    if (status >= 200 && status < 299) {
                        resolve({data, status});
                        return
                    }
                    reject({ data, status })
                    return
                })
            } catch (err) {
                console.error("Error during store", err);
                throw err;
            }
        });
    }

    const toggleStatusID = (id: any): Promise<StoreResponse> => {
        const token: string = session.token;
        return new Promise((resolve, reject) => {
            const headers = getHeaderInstance({
                Authorization: 'Bearer ' + token
            });
            const config: any = {
                headers,
                timeout: 1000 * 25, // Wait for 25 seconds
                validateStatus: () => true
            };

            try {
                instance.post(`idcards/toogle-status/${id}`, {}, config).then((response: any) => {
                    const { data, status } = response;
                    if (status >= 200 && status < 299) {
                        resolve({data, status});
                        return
                    }
                    reject({ data, status })
                    return
                })
            } catch (err) {
                console.error("Error during store", err);
                throw err;
            }
        });
    }
    return { get, getWithPhoto, search, storeIdStore, toggleStatusID };
};

export default useIdCardService;
