import React, { useState, useImperativeHandle, forwardRef, useRef } from "react";
import InputCardMakerHandle from "../interfaces/InputCardMakerHandle";
import InputCardMakerProps from "../interfaces/InputCardMakerProps";

/**
 * Generates a random 4-digit number ensuring uniqueness.
 * @returns {string} - A unique random 4-digit number as a string.
 */
const generateUniqueRandomNumber = () => {
    let randomNumber;
    randomNumber = Math.floor(Math.random() * 10000);
    // Pad the number with leading zeros if necessary
    randomNumber = randomNumber.toString().padStart(4, '0');
    return randomNumber;
};

const InputCardMaker = forwardRef<InputCardMakerHandle, InputCardMakerProps>((props, ref) => {
    const { label, name, type, required, observer } = props;
    const [value, setValue] = useState<string>(props.value || "");
    const [isValid, setIsValid] = useState<boolean>(true); // Nuevo estado para rastrear la validez
    const inputRef = useRef<HTMLInputElement>(null);

    // Generar unique ID para este input
    const uniqueId = useRef<string>(generateUniqueRandomNumber()).current;
    const inputName = `input-${name}_${uniqueId}`;
    const inputId = `input-${name}_${uniqueId}`;

    const validateValue = (valueToValidate: string): boolean => {
        if (required && valueToValidate === "") {
            return false; // Si es requerido y está vacío, no es válido
        }
        switch (type) {
            case 'number':
                return /^\d+$/.test(valueToValidate);
            case 'email':
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valueToValidate);
            case 'phone':
                return /^\+?\d{10,15}$/.test(valueToValidate);
            case 'alphabetic':
                return /^[a-zA-Z\s]*$/.test(valueToValidate);
            case 'curp':
                return /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/.test(valueToValidate.toUpperCase());
            case 'text':
            default:
                return true; // 'text' y tipos no especificados siempre son válidos
        }
    };

    useImperativeHandle(ref, () => ({
        clean() {
            setValue("");
            setIsValid(true); // Restablecer la validez al limpiar
        },
        validate() {
            return validateValue(value);
        },
        getInputRef() {
            return inputRef.current;
        },
        get() {
            return value;
        }
    }));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);
        const currentIsValid = validateValue(newValue);
        setIsValid(currentIsValid);
        if (observer) {
            observer(newValue);
        }
    };

    return (
        <div className="formItem">
            {label && (
                <div className="label">
                    {!isValid && <p>*</p>}
                    <label htmlFor={inputId}>{label}</label>
                </div>
            )}
            <div className="input">
                <input
                    ref={inputRef}
                    name={inputName}
                    id={inputId}
                    type={type === 'alphabetic' || type === 'curp' ? 'text' : type}
                    value={value}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
});

export default InputCardMaker;
