import React from 'react'
import './style.scss'

const AnimationDots = (props) => {
  return (
    <div className="animation-dots">
      <b>.</b><b>.</b><b>.</b>
    </div>
  )
}

export default AnimationDots
