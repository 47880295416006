import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { faUser, faArrowRightFromBracket, faSchool, faServer, faIdCard } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import Header from "./components/Header";
import useAuth from "@hooks/useAuth";
import useLocalStorage from "@hooks/useLocalStorage";
import { useCallback, useEffect, useState } from "react";
import useActionInProcess from "@hooks/useActionInProcess";
import useSwal from "@hooks/useSwal";
import useSIAOperationsService from "@services/sia.operations.service";


const DashboardView = (props: any) => {
    const {wait,  setActionWait, hideWait, showWait} = useActionInProcess()
    const {auth} = useAuth();
    const { alert, question, toast } = useSwal();
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth } = useAuth();
    const {getLS, removeLS} = useLocalStorage();
    
    const {logon} = useSIAOperationsService();

    const [logonChecked, setLogonChecked] = useState(false);

    const { pathname } = location;

    const handleLogout = useCallback(() => {
        setAuth(null)
        removeLS("token")
        setActionWait(false)
        navigate("/login", { replace: true });
        toast("success", "Sesión Finalizada, Hasta luego!");
    }, [navigate, removeLS, setActionWait, setAuth, toast])
    
    const handleActionLogout = () => {
        question(`¿Esta seguro de cerrar su sesión?.`, true, "Continuar", "Cancelar", "center", true).then(() => {
            handleLogout()
        });
         
    }

    const navigatorOptions = [
        {
            to: "/usuarios",
            path: "usuarios",
            icon: faUser,
            label: "Usuarios"
        },
        {
            to: "/eventos",
            path: "eventos",
            icon: faSchool,
            label: "Eventos"
        },
        {
            to: "/servicios",
            path: "servicios",
            icon: faServer,
            label: "Operaciones y Servicios"
        },
        {
            to: "/credenciales",
            path: "credenciales",
            icon: faIdCard,
            label: "Credencialización"
        }
    ]

    const rightButtons = [
        {
            action: handleActionLogout,
            icon: faArrowRightFromBracket
        }
    ]

    useEffect(()=>{
        if(Object.keys(auth).length === 0 && !wait){
            showWait("Ingresando a su cuenta...")
            const token = getLS("token");
            
            if(token ){
                if(!logonChecked){
                    setLogonChecked(true);                    
                    logon().then((response: any) => {
                        const {statusCode, data, operationStatus} = response;
                        if(operationStatus){
                            setAuth(data);
                            hideWait();
                        } else if ([401, 403].includes(statusCode)){
                            alert("error", "Acceso Denegado")
                            hideWait();
                            setAuth(undefined);
                            handleLogout()
                        }
                    }).catch((err: any) => {
                        handleLogout()
                    })
                }
                
            } else {
                handleLogout()
            }

            return () => {
                
            }
        }
        return () => {

        }
    }, [auth, getLS, handleLogout, logon, showWait, wait, logonChecked, setAuth, hideWait, alert])

    return <div className="DashboardView w-full h-screen bg-custom-dark bg-opacity-90">
        <Header currentPath={pathname} options={navigatorOptions} rightButtons={rightButtons} />
        {
            Object.keys(auth).length > 0 && 
            <div className="main w-full h-full flex">
                <Outlet />
            </div>
        }
    </div>
}

export default DashboardView;