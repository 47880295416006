import React from 'react';

const Submit = (props: any) => {
    const { className, disabled, label, onClick }: { className: string, disabled: boolean, label: string, onClick: any } = props;
    return <button className={[className].join(" ")} type="submit" disabled={disabled} onClick={onClick}>{label ? label : props.children}</button>
}

const Button = (props: any) => { //TODO: create logic
    const { className, disabled, label, onClick }: { className: string, disabled: boolean, label: string, onClick: any } = props;
    return <button className={[className].join(" ")} type="button" disabled={disabled} onClick={onClick}>{label ? label : props.children}</button>
}

// const ButtonCopiable = (props: any) => {
    //TODO: create a buton with a value, when user touch it the value is sent to clipboard, when this process has been completed, the component send a trigger event to inform than the content has been copied in clipboard.
// }

export {
    Button,
    Submit
}