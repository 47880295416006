import { NavLink } from 'react-router-dom';
import logo from "@assets/images/logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import packageJson from '../../../../package.json';

const Brand = (props: any) => {
    return <NavLink className="flex mx-2 p-2 w-80" to="/" >
        <img src={logo} alt="logotipo-app" className="w-10" />
        <h1 className="font-bold flex flex-col justify-start items-start ml-4 text-white">
            <span className="text-xs">SIA</span>
            <span className="text-xs">Administrator System {packageJson.version}</span>
        </h1>
    </NavLink>
}

interface MenuInterface {
    to: string,
    path: string,
    icon: any,
    label: string
}

const Menu = (props: any) => {
    const { options, currentPath } = props;
    const splitLocation = currentPath.split("/");

    return <div className="w-full navigator-options flex items-center h-full">
        {
            options && options.map(
                (item: MenuInterface, key: number) => <NavLink
                    to={item.to}
                    key={key}
                    className={
                        [
                            "flex flex-row mx-3 transition-custom py-2 border-b-2",
                            splitLocation[0] === item.path && splitLocation[1] === "" ? "text-blue-400 font-bold border-b-blue-400" : "text-gray-300 border-b-transparent",
                            "hover:text-custom-orange hover:border-b-custom-orange hover:font-bold"
                        ].join(" ")
                    }
                >
                    <FontAwesomeIcon icon={item.icon} className="mr-2 text-md shadow-sm" />
                    <span className="text-sm">{item.label}</span>
                </NavLink>)
        }
    </div>
}

const ButtonControlHeader = (props: any) => {
    const {action, classes, icon} = props
    return action && icon && <div className={
        [
            "user-control-option rounded-full w-10 h-10 shadow-sm flex items-center justify-center text-center cursor-pointer",
            classes
        ].join(" ")} onClick={action}>
        <FontAwesomeIcon icon={icon} className="text-2xl" />
    </div>
}

const HeaderRightSection = (props: any) => {
    const {buttons} = props
    return <div className="w-80 user-controls flex items-center justify-end h-full pr-6">
        {
            buttons && buttons.map((button: any, key: number) => {
                return <ButtonControlHeader key={key} icon={button.icon} action={button.action} classes="transition-custom text-white bg-custom-red hover:bg-white hover:text-black mr-2"/>
            })}
    </div>
}

const Header = (props: any) => {
    const { currentPath, options, rightButtons } = props;

    return <div className="navigation-header flex justify-center items-center shadow h-20 z-10 bg-custom-dark-contrast sticky top-0">
        <Brand />
        <Menu options={options} currentPath ={currentPath}/>
        <HeaderRightSection buttons={rightButtons}/>
    </div>
}

export default Header