import FormDataAPI from '@models/form.data.api.model';
import constants from '@constants/index';
import LoginPayloadResponse from '@interfaces/LoginPayloadResponse';
import { isSuccessCode } from 'classes/HttpTools';
import useLocalStorage from '@hooks/useLocalStorage';
import useRequest from '@hooks/useRequest';
import ResponseObject from '@interfaces/ResponseObject';

const useSIAOperationsService = () => {
    const {getLS} = useLocalStorage();

    const {SIA_API}: any = constants;
    const { post, hideWait } = useRequest(SIA_API);


    const logon = async () => {
        // const token = getLS("token");
        // console.log("current token", token);

        const endpoint = `dashboard/logon`;  
        const result = await post(endpoint, {message: "Validando tu cuenta SIA", autohide: true}, {});
        
        const { data, message, statusCode }: ResponseObject = result.response;
        // console.log("pure response", result);
        
        if ([200, 201].includes(statusCode)) {
            const payload:LoginPayloadResponse = {
                data,
                token: data.token || null,
                statusCode: statusCode,
                message: message,
                operationStatus: isSuccessCode(statusCode)
            }
            return payload;
        } else {
            const payload:LoginPayloadResponse = {
                data: null,
                token: undefined,
                statusCode: statusCode,
                message: message,
                operationStatus: false
            }
            hideWait()
            return payload;
        }
        
        // return new Promise((resolve, reject) => {
        //     const headers = getHeaderInstance({
        //         Authorization: 'Bearer ' + token
        //     });
        //     const config = {
        //         headers,
        //         validateStatus: () => true
        //     }
            
        //     instance.post('logon', {}, config).then((response: any) => {
        //         console.log("pure logon repsonse", response);
                
        //         const { data, status } = response
        //         if(status===200){
        //             resolve(data)
        //             return
        //         }
        //         reject(data)
        //     }).catch((err: any) => {
        //         reject(err)
        //     });
        // })
    }

    

    return {logon}
}

export default useSIAOperationsService;