import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SubMenuInterface {
    path: string,
    icon: any,
    classes: string,
    label: string
}

const SubMenuOption = (props: any) => {
    const { classes, icon, label, path } = props;

    return <button
        className="submenu-option flex flex-col w-24 h-24 items-center justify-center m-0"
    >
        <NavLink className={
            ({isActive}) => [
                "container-submenu-option w-20 h-20 flex flex-col text-white items-center justify-center rounded-sm shadow-sm transition-custom active:scale-95 hover:bg-custom-orange hover:text-dark",
                isActive ? "bg-red-700 animate-pulse" : "",
                classes
            ].join(" ")
        } to={path}>
            <FontAwesomeIcon icon={icon} className="mr-2 text-2xl mb-1" />
            <span className="text-xs">{label}</span>
        </NavLink>
    </button>
}

const InternalNavigator = (props: any) => {
    const { options } = props

    return <div className="navigation-internal bg-darkness-contrast w-28 h-full flex flex-col p-0 m-0 justify-start items-center bg-custom-dark-alternative">
        {
            options && options.map(
                (item: SubMenuInterface, key: number) => <SubMenuOption key={key} classes={item.classes} path={item.path} icon={item.icon} label={item.label}/>)
        }
    </div>
}

export default InternalNavigator