import React, { FC, lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';

// Importaciones de activos (imágenes, estilos)
import { Background } from '@assets/images';
import './styles.scss';

// Importaciones de hooks personalizados
import useLocalStorage from '@hooks/useLocalStorage';

// Importaciones de componentes locales

import DashboardView from './Dashboard';
import FullFailBackScreen from '@components/ui/FullFailBackScreen/FullFailBackScreen.component';
import IDCardsModule from './IDCards';

const LoginModule = lazy(() => import('./Login'));
const UsersModule = lazy(() => import('./Users'));
const NotFound = lazy(() => import('./NotFound'));
const EventsModule = lazy(() => import('./Events/index'));
const ServicesModule = lazy(() => import('./Services'));

const App: FC = () => {
    const navigate = useNavigate();
    const {getLS} = useLocalStorage();

    useEffect(() => {
        const userTokenValue = getLS("token");
        if (!userTokenValue) {
            navigate("/login")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);//getUserToken, navigate

    return <div className="m-0 p-0 w-full h-screen overflow-hidden" style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}>
        <Suspense fallback={<FullFailBackScreen />}>
            <Routes>
                <Route path="/login" element={<LoginModule />} />
                <Route path="/" element={<DashboardView />}>
                    <Route index element={<Navigate to="/usuarios" />} />
                    <Route path="/usuarios/*" element={<UsersModule />} />
                    <Route path="/eventos/*" element={<EventsModule />} />
                    <Route path="/servicios/*" element={<ServicesModule />} />
                    <Route path="/credenciales/*" element={<IDCardsModule />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Suspense>
        <div id="root-actions" />
        <div id="root-modal" />
    </div>
}

export default App;