const CloudUploadIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Uploaded to svgrepo.com"
        viewBox="0 0 32 32"
        {...props}
    >
        <style>{".st0{fill:#a3afaf}.st1{fill:#8d9999}.st2{fill:#8c9898}"}</style>
        <path
            d="M28 13h-1a6 6 0 0 0-6-6h-.587A7.997 7.997 0 0 0 13 2a8 8 0 0 0-8 8v1a4 4 0 0 0 0 8h23a3 3 0 1 0 0-6z"
            style={{
                fill: "#e1e5e5",
            }}
        />
        <path
            d="m14 9 10 10H11v-2H8z"
            style={{
                fill: "#c4cccc",
            }}
        />
        <path
            d="m8 17 6-8 6 8h-3v13h-6V17z"
            style={{
                fill: "#fdffff",
            }}
        />
    </svg>
)
export default CloudUploadIcon
