import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./style.scss";

const StyleTable = {
    dark: {
        thead: "bg-gray-900",
        thIndex: "text-gray-200 bg-gray-800 text-center",
        th: {
            style: "text-gray-200 text-center font-extrabold",
            activeHover: "hover:text-blue-600 cursor-pointer"
        },
        rows: {
            tr: {
                odd: "bg-gray-600 text-white text-center hover:bg-gray-900",
                even: "hover:bg-gray-50 bg-gray-700 text-white text-center hover:bg-gray-900",
                tdHoverCopiable: "hover:text-blue-500"
            },
            trIndex: "bg-gray-900",
            trSelected: {
                style: "cursor-pointer bg-blue-800 hover:bg-blue-900"
            }
        },
        paginator: {
            style: "border-gray-700 bg-gray-900 border-opacity-50 bg-opacity-0 mt-4",
            metadata: "text-gray-100",
            button: "border-gray-800 bg-gray-900 text-gray-200 hover:bg-gray-800",
            buttonActive: "border-gray-800 bg-gray-800 text-gray-200"
        },
        filter: {
            label: "text-gray-200",
            filterIndicator: "text-yellow-400 hover:text-red-500",
            nullIndicator: "text-gray-200"
        },
        search: {
            box: "bg-gray-300 bg-opacity-20",
            input: "text-gray-200",
        }
    },
    light: {
        thead: "bg-white",
        thIndex: "text-gray-900 bg-gray-100 text-center",
        th: {
            style: "text-gray-900 text-center font-extrabold",
            activeHover: "hover:text-blue-600 cursor-pointer"
        },
        rows: {
            tr: {
                odd: "bg-white text-center hover:bg-gray-200",
                even: "hover:bg-gray-200 bg-gray-50 text-center hover:bg-gray-50",
                tdHoverCopiable: "hover:text-blue-600"
            },
            trIndex: "bg-gray-100",
            trSelected: {
                style: "cursor-pointer bg-blue-200 hover:bg-blue-300"
            }
        },
        paginator: {
            style: "border-gray-200 bg-white",
            metadata: "text-gray-700",
            button: "border-gray-300 bg-white text-gray-500 hover:bg-gray-200",
            buttonActive: "border-indigo-200 bg-indigo-100 text-indigo-600"
        },
        filter: {
            label: "text-dark",
            filterIndicator: "text-blue-600 hover:text-red-500",
            nullIndicator: "text-gray-500"
        },
        search: {
            box: "bg-gray-300 bg-opacity-50"
        }
    }

}

const SearchSvgIcon = (props: any) => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props} >
        <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z" />
    </svg>
}

const buildItems = (current: number, last: number) => {
    let items: any = [];
    if (current === 1) {
        for (let i = current; i <= last; i++) {
            if (items.length < 5) {
                items.push(i);
            }
        }
    } else if (current === last) {
        for (let i = last; i > 0; i--) {
            if (items.length < 5) {
                items.push(i);
            }
        }
    } else {
        let min = last - 1 === current ? 3 : 2

        for (let i = current - 1; i > 0; i--) {
            if (items.length < min) {
                items.push(i);
            }
        }

        for (let i = current + 1; i <= last; i++) {
            if (items.length < 4) {
                items.push(i);
            }
        }
        items.push(current)
    }

    return items.sort((a: number, b: number) => a - b);
}

const PaginationOptions = ({ metadata, styles, handleGotoPage, handleGoToBack, handleGotoNext }: any) => {
    const { current_page, from, last_page, to, total } = metadata

    let items: Array<number> = buildItems(current_page, last_page);

    return <div className={["flex items-center justify-between border-t px-4 py-3 sm:px-6", styles.style].join(" ")}>
        <div className="flex flex-1 justify-between sm:hidden">
            <button onClick={handleGoToBack} className={["relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium", styles.button].join(" ")}>Anterior</button>
            <button onClick={handleGotoNext} className={["relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium", styles.button].join(" ")}>Siguiente</button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
                {
                    metadata && from && to && total ? <p className={["text-sm", styles.metadata].join(" ")}>
                        Mostrando
                        <span className="mx-1 font-extrabold">{metadata && from}</span>
                        a
                        <span className="mx-1 font-extrabold">{metadata && to}</span>
                        de
                        <span className="mx-1 font-extrabold">{metadata && total}</span>
                        registros
                    </p>
                        :
                        <p className={["text-sm", styles.metadata].join(" ")}>
                            Sin Datos
                        </p>
                }
            </div>
            <div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <button onClick={handleGoToBack} className={["relative inline-flex items-center rounded-l-md border px-2 py-2 text-sm font-medium focus:z-20", styles.button].join(" ")}>
                        <span className="sr-only">Anterior</span>
                        <svg className="h-5 w-5" x-description="Heroicon name: mini/chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd"></path>
                        </svg>
                    </button>

                    {
                        items && items.map((item: number, key: number) => {
                            return <button
                                key={key}
                                className={["relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20", item === current_page ? styles.buttonActive : styles.button].join(" ")}
                                onClick={(item === current_page ? () => { } : () => handleGotoPage(item))}
                            >{item}</button>
                        })
                    }

                    <button onClick={handleGotoNext} className={["relative inline-flex items-center rounded-r-md border px-2 py-2 text-sm font-medium focus:z-20", styles.button].join(" ")}>
                        <span className="sr-only">Siguiente</span>
                        <svg className="h-5 w-5" x-description="Heroicon name: mini/chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </nav>
            </div>
        </div>
    </div>
}

const TableHeaderButton = (props: any) => {
    const { icon, classes, onClick, title } = props;

    return <button className={["text-white w-10 h-10 rounded-sm shadow-sm", classes ? classes : "bg-gray-700 hover:bg-gray-500 active:bg-gray-900"].join(" ")} onClick={onClick ? onClick : null} title={title}>
        <FontAwesomeIcon icon={icon} className="text-white" />
    </button>
}

const Table = (props: any) => {
    const { columns, copiable, darkMode, filter, headerOptions, limit, notResultsLabel, onContextMenu, onCopyAction, onHeaderSelect, onRowDoubleClick, onSearch, searchAutofocus, onRenderOptions, noHeader, noSearch, nullValue, metadata, onPageChange, rows, parseLinks } = props;
    const [query, setQuery] = useState<string>("");
    const [selected, setSelected] = useState<number | null>(null);

    const handleCopyAction = (meta: string) => {
        navigator.clipboard.writeText(meta);
        if (onCopyAction) {
            onCopyAction(meta)
        }
    }

    const handleSearchAction = () => {
        if (query && query.trim() !== "") {
            setSelected(null)
            onSearch(query)
        }
    }

    const handleColumnHeaderTouched = (props: any = null) => {
        if(onHeaderSelect){
            onHeaderSelect(props)
        }
    }

    const styles: any = darkMode ? StyleTable.dark : StyleTable.light

    const handleGotoPage = (item: number) => {
        setSelected(null)
        onPageChange(item, query)
    }

    const handleGoToBack = () => {
        const { current_page } = metadata
        if (current_page > 1) {
            setSelected(null)
            onPageChange(current_page - 1, query)
        }
    }

    const handleGotoNext = () => {
        const { current_page, last_page } = metadata
        if (current_page < last_page) {
            setSelected(null)
            onPageChange(current_page + 1, query)
        }
    }

    const keyboardListener = (event: any) => {
        if (query && query !== "") {
            if ((event.code).toLowerCase() === "enter" || (event.key).toLowerCase() === "enter") {
                onSearch(query)
            }
        }
    }

    const handleRowDoubleClick = (payload: any) => {
        if(onRowDoubleClick){
            onRowDoubleClick(payload)
        }
    }

    const actionContextMenu = (event: any, data: any) => {
        event.preventDefault()
        if(onContextMenu){
            onContextMenu(data)
            return true
        }
    }

    const renderedRows = () => {
        // console.log(rows);

        if(rows) {
            let tmp =  rows.map((row: any, key: number) => {
                const odd = key % 2 === 0;
                return <tr className={[
                    odd ? ["border-b ", styles.rows.tr.odd].join(" ") : ["border-b", styles.rows.tr.even].join(" "),
                    "text-sm",
                    (key === selected) && styles.rows.trSelected.style,
                    onRowDoubleClick && "cursor-pointer"
                ].join(" ")} key={key} onDoubleClick={()=>handleRowDoubleClick(row)} onContextMenu={(event: any) => actionContextMenu(event, row)}>
    
                    {
                        columns && <td className={["numerator", styles.rows.trIndex].join(" ")} key={key} onClick={() => setSelected(key)}>
                            {key + 1}
                        </td>
                    }
    
                    {
                        columns && columns.map((column: any, key: number) => {
                            let value = row[column.value];
                            if (parseLinks) {
                                if (typeof value === "string" && value.startsWith("http")) {
                                    value = <a className={["cursor-pointer", styles.rows.tr.tdHoverCopiable].join(" ")} target="__BLANK" href={value}>{value}</a>
                                }
                            }
    
                            if (copiable) {
                                return <td className={["py-4 px-6 stop-stretching cursor-pointer", styles.rows.tr.tdHoverCopiable].join(" ")} key={key} onClick={() => handleCopyAction(row[column.value])}>
                                    {value ? value : nullValue ? nullValue : ""}
                                </td>
                            }
    
                            return <td className="py-4 px-6 stop-stretching" key={key}>
                                {value ? value : nullValue ? nullValue : ""}
                            </td>
                        })
                    }
                    {
                        onRenderOptions && <td>{onRenderOptions(row, key)}</td>
                    }
                </tr>
            })

            if(limit){
                return tmp.splice(0, limit);
            } else {
                return tmp;
            }
        }
        return [];
    }

    return <div className="flex flex-col noDraggable">
        {
            !noHeader && <>
                <div className="w-full noSelectable">
                    <div className={["w-full text-sm font-medium text-left py-3 px-1 stop-sdivetching", styles.th].join(" ")}>
                        <div className={[noSearch ? "flex-col" : "flex-row", "w-full h-10 flex justify-center items-center overflow-hiddens"].join(" ")}>
                            <div className={[noSearch ? "w-full" : "w-3/6", " flex flex-row justify-start items-center gap-3"].join(" ")}>
                                {
                                    headerOptions
                                }
                            </div>
                            <div className="text-center w-3/6 flex flex-row justify-end items-center">
                                {
                                    !noSearch && <>
                                        <div className="searchFilter">
                                            <span className={["text-center text-sm mr-4", styles.filter.label].join(" ")}><b>Filtro:</b> {filter ? <span title="Haz clic aquí para limpiar el filtro" className={["cursor-pointer", styles.filter.filterIndicator].join(" ")} onClick={() => handleColumnHeaderTouched(null)}>{filter.label}</span> : <span className={["", styles.filter.nullIndicator].join(" ")}>Ninguno</span>}</span>
                                        </div>
                                        <div className={["searchBox rounded-sm w-1/2 h-9 flex flex-row items-center justify-center", styles.search.box].join(" ")}>
                                            <input
                                                className={["searchInput w-5/6 h-full bg-transparent rounded-sm px-3 outline-none text-sm", styles.search.input].join(" ")}
                                                placeholder="Ingresa tu consulta"
                                                onKeyDown={keyboardListener}
                                                onChange={(event) => setQuery(event.target.value)}
                                                type="text"
                                                autoFocus={searchAutofocus}
                                            />
                                            <button className="actionSearch w-1/6">{<SearchSvgIcon className="text-white" fill="#aaa" width="24px" height="24px" onClick={handleSearchAction} />}</button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 noSelectable">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden rounded-t-lg">

                    <table className="min-w-full">
                        <thead className={["border-b", styles.thead].join(" ")}>
                            <tr>
                                {
                                    columns && <th scope="col" className={["text-sm font-medium px-6 py-4 text-left numerator", styles.thIndex].join(" ")} onClick={() => setSelected(null)}>
                                        #
                                    </th>
                                }
                                {
                                    columns && columns.map((column: any, key: number) => <th scope="col" className={["text-sm font-medium text-left py-3 px-6 stop-stretching", styles.th.style, onHeaderSelect ? styles.th.activeHover : ""].join(" ")} key={key} onClick={() => handleColumnHeaderTouched(column)}>
                                        {column.label}
                                    </th>)
                                }

                                {
                                    onRenderOptions && <th scope="col" className={["text-sm font-medium px-6 py-4 text-left numerator", styles.th.style].join(" ")} onClick={() => setSelected(null)}>
                                        Opciones
                                    </th>
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {
                                renderedRows()
                            }

                            {
                                (!rows || rows.length === 0) && <tr className={[
                                    "border-b ", styles.rows.tr.odd,
                                    "text-xs"
                                ].join(" ")}>
                                    <td className={["numerator p-4", styles.rows.trIndex].join(" ")} colSpan={(columns.length + 1) + (onRenderOptions ? 1 : 0)}>
                                        <span className="text-lg">
                                            {notResultsLabel ? notResultsLabel : "No hay resultados"}
                                        </span>
                                    </td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        {
            metadata && <PaginationOptions handleGotoPage={handleGotoPage} handleGoToBack={handleGoToBack} handleGotoNext={handleGotoNext} metadata={metadata} styles={styles.paginator} />
        }

    </div>
}

export default Table;

export {
    TableHeaderButton
};