import IDCardMaker from '@components/advancedComponents/IDCardMaker';
import { faArrowLeftLong, faCancel, faCheck, faEye, faIdCard, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useActionInProcess from '@hooks/useActionInProcess';
import useSwal from '@hooks/useSwal';
import useIdCardService from '@services/idcard.service';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@components/ui/Modal';
import { Button } from '@components/form/Button';
import { humanizeDateString } from 'classes/dateUtils';

const ValueBox = ({ label, children, small }: { label: string, children: any, small?: boolean }) => {
    return <span className="flex flex-row text-white my-1">
        <p className={`text-${small ? 'md' : 'xl'} font-bold mr-2`}>{label ? label : "Valor"}: </p>
        <p className={`text-${small ? 'md' : 'xl'} text-yellow-400`}>{children}</p>
    </span>
};


interface CardIDResponseData {
    photo: string,
    status: number,
    front: {
        cardID: string,
        nombre: string,
        matricula: string,
        carrera: string,
        tipo: string
    },
    back: {
        curp: string,
        correo: string,
        direccion: string,
        ciudad: string,
        estado: string,
        cp: string,
        donador: string,
        nombre_emergencia: string,
        nss: string,
        sangre: string,
        telefono: string,
        telefono_emergencia: string,

    },
    user_id: string
}

interface TimeStamps{
    creationDate?: string,
    updateDate?: string,
}

const IDCardInformation = (props: any) => {
    const { setViewTitle } = props
    const navigate = useNavigate();
    let location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id: string | null = queryParams.get('id');
    const { alert, question } = useSwal();
    const { wait, hideWait, showWait } = useActionInProcess()
    const [iDCardInformationPayload, setIDCardInformationPayload] = useState<CardIDResponseData | undefined>(undefined);
    const { getWithPhoto, toggleStatusID } = useIdCardService();
    const [renderMode, setRenderMode] = useState<boolean>(false);
    const [timestamps, setTimestamps] = useState<TimeStamps | undefined>(undefined);

    const handleGetIdInformation = useCallback(() => {
        if (id) {
            showWait("Cargando la información de la credencial");
            getWithPhoto(id).then((response: any) => {
                const { data, status } = response;
                if (status === 200) {
                    const {
                        card_id,
                        carrera,
                        ciudad,
                        correo,
                        cp,
                        curp,
                        direccion,
                        donador,
                        estado,
                        matricula,
                        nombre,
                        nombre_emergencia,
                        nss,
                        photo,
                        sangre,
                        status,
                        telefono,
                        telefono_emergencia,
                        tipo,
                        user_id,
                        updated_at,
                        created_at
                    } = data;
                    

                    const dataEndPayload: CardIDResponseData = {
                        user_id,
                        status,
                        photo,
                        front: {
                            cardID: card_id,
                            nombre,
                            matricula,
                            carrera,
                            tipo
                        },
                        back: {
                            curp: curp.toUpperCase(),
                            telefono,
                            correo: correo.toLowerCase(),
                            direccion: direccion.toUpperCase(),
                            ciudad: ciudad.toUpperCase(),
                            estado: estado.toUpperCase(),
                            cp,
                            telefono_emergencia,
                            nombre_emergencia: nombre_emergencia.toUpperCase(),
                            nss,
                            sangre: sangre.toUpperCase(),
                            donador: donador.toUpperCase()
                        }
                    };

                    setTimestamps({
                        creationDate: humanizeDateString(created_at, " a las ", "upper"),
                        updateDate: humanizeDateString(updated_at, " a las ", "upper")
                    })
                    setIDCardInformationPayload(dataEndPayload);
                    hideWait()
                    return
                }
                alert("error", "No hay información en el sistema", 5000, true)
            }).catch((err: any) => {
                console.log("error", err);
                alert("error", err, 5000, true)
            })
        }
    }, [id, getWithPhoto, showWait, alert, setIDCardInformationPayload, hideWait])

    useEffect(() => {
        if (!wait && id && iDCardInformationPayload === undefined) {
            handleGetIdInformation()
        }
    }, [wait, id, iDCardInformationPayload, handleGetIdInformation]);

    useEffect(() => {
        setViewTitle("Generador de ID's")
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleGenerateIDCard = () => {
        setRenderMode(true);
    }

    const handleToogleStatus = () => {
        question(`¿Esta seguro de ${iDCardInformationPayload?.status ? "Cancelar" : "Reactivar"} la identificación?`).then(() => {
            showWait("Cambiando el estado de la identificación, espere un momento");
            toggleStatusID(id).then((result: any) => {
                hideWait()
                setIDCardInformationPayload((current: any) => {
                    return { ...current, status: result.data.status }
                })
                alert("success", "Operación completada con exito!");
            }).catch((error: any) => {
                alert("error", "Ocurrio un error al actualizar la información")
                hideWait()
            })
        })
    }

    return <>
        <div className="flex flex-row w-full justify-center items-center">
            <div className="flex flex-col justify-start items-start w-full">
                <div className="w-full relative left-4 flex flex-row items-end justify-start mt-5 p-4">
                    {
                        iDCardInformationPayload && <div className="w-full flex">

                            <div className="w-1/5">
                                <div className="profilePhoto w-56 h-56 overflow-hidden flex flex-row justify-center items-center rounded-md relative mt-14">
                                    {
                                        iDCardInformationPayload?.photo && <div className="image-profile flex items-center justify-center">
                                            <img src={iDCardInformationPayload?.photo} alt="" className="photo w-full" />
                                        </div>
                                    }
                                </div>
                            </div>


                            <div className="w-4/5 flex flex-col">

                                <div className="w-full mb-2 justify-end items-center flex gap-2">
                                    {
                                        iDCardInformationPayload?.status === 1 && <Button className="bg-blue-700 hover:bg-blue-800 active:bg-blue-600 rounded-full h-10 w-10" title="Generate ID Card" onClick={handleGenerateIDCard}>
                                            <FontAwesomeIcon icon={faThumbsUp} className=" text-xl text-white text-shadow-xl" />
                                        </Button>
                                    }

                                    {
                                        iDCardInformationPayload?.status ?
                                            <Button className="bg-red-700 hover:bg-red-800 active:bg-red-600 rounded-full h-10 w-10" onClick={handleToogleStatus} title="Cancelar Identificación">
                                                <FontAwesomeIcon icon={faCancel} className=" text-xl text-white text-shadow-xl" title="Cancelar Identificación" />
                                            </Button>
                                            :
                                            <Button className="bg-green-700 hover:bg-green-800 active:bg-green-600 rounded-full h-10 w-10" onClick={handleToogleStatus} title="Reactivar Identificación">
                                                <FontAwesomeIcon icon={faCheck} className=" text-xl text-white text-shadow-xl" title="Reactivar Identificación" />
                                            </Button>}

                                    <Button className="bg-blue-500 hover:bg-blue-400 active:bg-blue-600 rounded-full h-10 w-10" onClick={() => navigate(-1)}>
                                        <FontAwesomeIcon icon={faArrowLeftLong} className=" text-xl text-white text-shadow-xl" title="volver" />
                                    </Button>
                                </div>

                                <div className="w-full mb-2 flex flex-col justify-start align-center items-start">
                                    <div className="w-full mb-2 flex flex-row justify-start align-center items-start">
                                        <FontAwesomeIcon icon={faIdCard} className=" text-4xl text-white text-shadow-xl mr-2" />
                                        <ValueBox label="Estado de la credencial">{iDCardInformationPayload?.status ? "Vigente" : "Identificación Cancelada"}</ValueBox>
                                    </div>

                                    <ValueBox label="Nombre">{iDCardInformationPayload?.front.nombre}</ValueBox>
                                    {
                                        timestamps && <>
                                            <ValueBox label="Fecha de registro" small>{timestamps?.creationDate}</ValueBox>
                                            <ValueBox label="Última Actualización" small>{timestamps?.updateDate}</ValueBox>
                                        </>
                                    }
                                </div>


                                <div className="w-full flex border-slate-500 border-2 p-2">
                                    <div className="w-1/2 flex flex-col">
                                        <ValueBox label="Matricula" small>{iDCardInformationPayload?.front?.matricula}</ValueBox>
                                        <ValueBox label="CURP" small>{("" + iDCardInformationPayload?.back.curp).toUpperCase()}</ValueBox>
                                        <ValueBox label="Numero de seguro social" small>{("" + iDCardInformationPayload?.back.nss).toUpperCase()}</ValueBox>
                                        <ValueBox label="Email" small>{("" + iDCardInformationPayload?.back.correo).toLowerCase()}</ValueBox>
                                        <ValueBox label="Teléfono Personal" small>{iDCardInformationPayload?.back.telefono}</ValueBox>
                                    </div>


                                    <div className="w-1/2 flex flex-col">
                                        <ValueBox label="Teléfono de Emergencia" small>{iDCardInformationPayload?.back.telefono_emergencia}</ValueBox>
                                        <ValueBox label="Nombre del contácto de Emergencia" small>{iDCardInformationPayload?.back.nombre_emergencia}</ValueBox>
                                        <ValueBox label="Domicilio" small>{iDCardInformationPayload?.back.direccion}, {iDCardInformationPayload?.back.ciudad}, {iDCardInformationPayload?.back.estado}, {iDCardInformationPayload?.back.cp}</ValueBox>
                                        <ValueBox label="Tipo de sangre" small>{("" + iDCardInformationPayload?.back.sangre).toUpperCase()}</ValueBox>
                                        <ValueBox label="¿Es Donador?" small>{("" + iDCardInformationPayload?.back.donador).toUpperCase()}</ValueBox>
                                    </div>
                                </div>
                                <div className="w-full flex justify-around p-2">
                                    <div className="w-1/3 flex flex-col">
                                        <ValueBox label="Tipo de identificación" small>{("" + iDCardInformationPayload?.front.tipo).toUpperCase()}</ValueBox>
                                    </div>
                                    <div className="w-1/3 flex flex-col">
                                        <ValueBox label="Carrera" small>{("" + iDCardInformationPayload?.front.carrera).toUpperCase()}</ValueBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
        {
            renderMode && <Modal title="Generador de Credenciales Institucionales" icon={faEye} handleClose={() => setRenderMode(false)} modalFull>
                {
                    iDCardInformationPayload && <IDCardMaker data={iDCardInformationPayload} onFinish={()=>setRenderMode(false)}/>
                }
            </Modal>
        }
    </>
}

export default IDCardInformation;