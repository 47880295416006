import { useCallback } from 'react';

/**
 * Custom hook DataPreview
 * @returns {object} - The hook state and actions
 */
const useWindowDataPreview = () => {
    
    const userDataPreview = useCallback((payload: any) => {
        const previewHtml = `
            <!DOCTYPE html>
            <html lang="es">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Previsualización de Datos</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        background-color: #f4f4f4;
                        margin: 0;
                        padding: 20px;
                    }
                    .container {
                        max-width: 1200px;
                        margin: 0 auto;
                        background: #fff;
                        padding: 20px;
                        border-radius: 8px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    }
                    .content-blocks{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;
                        min-width: 70dvw;
                        gap: 4;
                        margin-top: 20px;
                    }
                    .section1{
                    }
                    .section2{
                    }
                    img{
                        border-radius: 100%;
                    }
                    h2 {
                        text-align: center;
                        color: #333;
                    }
                    .data-item {
                        display: flex;
                        justify-content: flex-start;
                        padding: 10px 0;
                        border-bottom: 1px solid #ddd;
                        gap: 8px;
                    }
                    .data-item:last-child {
                        border-bottom: none;
                    }
                    .data-item span {
                        font-weight: bold;
                    }

                    .data-item span.value{
                        color: #001ec5;
                    }
                </style>
            </head>
            <body>
                <div class="container">
                    <h2>Previsualización de Datos</h2>
                    ${ payload.photo ? '<div class="content-blocks"><div class="section1"> <img src="https://api-shield.upn164.edu.mx/pictures/thumbs/'+payload.photo+'" alt="" className="photo w-full" /></div>':''}
                    <div class="section2">
                    <div class="data-item"><span>Carrera:</span> <span class="value">${payload.carrera}</span></div>
                    <div class="data-item"><span>Ciudad:</span> <span class="value">${payload.ciudad.toUpperCase()}</span></div>
                    <div class="data-item"><span>Correo:</span> <span class="value">${payload.correo}</span></div>
                    <div class="data-item"><span>Código Postal:</span> <span class="value">${payload.cp}</span></div>
                    <div class="data-item"><span>CURP:</span> <span class="value">${payload.curp.toUpperCase()}</span></div>
                    <div class="data-item"><span>Dirección:</span> <span class="value">${payload.direccion.toUpperCase()}</span></div>
                    <div class="data-item"><span>Donador:</span> <span class="value">${payload.donador.toUpperCase()}</span></div>
                    <div class="data-item"><span>Estado:</span> <span class="value">${payload.estado.toUpperCase()}</span></div>
                    <div class="data-item"><span>Matrícula:</span> <span class="value">${payload.matricula}</span></div>
                    <div class="data-item"><span>Carrera:</span> <span class="value">${payload.career}</span></div>
                    <div class="data-item"><span>Career:</span> <span class="value">${payload.group}</span></div>
                    <div class="data-item"><span>Nombre:</span> <span class="value">${payload.nombre}</span></div>
                    <div class="data-item"><span>Nombre de Emergencia:</span> <span class="value">${payload.nombre_emergencia.toUpperCase()}</span></div>
                    <div class="data-item"><span>NSS:</span> <span class="value">${payload.nss.toUpperCase()}</span></div>
                    <div class="data-item"><span>Tipo de Sangre:</span> <span class="value">${payload.sangre.toUpperCase()}</span></div>
                    <div class="data-item"><span>Teléfono:</span> <span class="value">${payload.telefono}</span></div>
                    <div class="data-item"><span>Teléfono de Emergencia:</span> <span class="value">${payload.telefono_emergencia}</span></div>
                    <div class="data-item"><span>Tipo:</span> <span class="value">${payload.tipo.toUpperCase()}</span></div>
                    </div>
                    </div>
                </div>
                <script>
                    // Agregar listener de teclado para cerrar la ventana
                    document.addEventListener('keydown', function() {
                        event.preventDefault();
                        event.stopPropagation();
                        window.close();
                    });
                </script>
            </body>
            </html>
        `;

        const availWidth = window.screen.availWidth;
        const availHeight = window.screen.availHeight;

        const previewWindow: any = window.open('', '_blank', 'width=' + availWidth + ',height=' + availHeight);
        previewWindow.document.write(previewHtml);
        previewWindow.document.close();
        previewWindow.moveTo(0, 0);
        previewWindow.resizeTo(availWidth, availHeight);
    }, [])

    const userConfirmationRegisterDataPreview = useCallback((payload: any) => {
        return new Promise((resolve, reject) => {
            const {
                birthdate,
                bloodType,
                cityAddress,
                cpAddress,
                dni,
                email,
                emergencyContactName,
                emergency_phone,
                enrollment,
                career,
                group,
                firstname,
                homeAddress,
                isDonor,
                lastname,
                nss,
                personal_phone,
                pseudo,
                rfc,
                sexo,
                stateAddress,
                surname,
            } = payload;

            const valueRow = (title: string, value: string | number) => `<div class="data-item"><span>${title}:</span> <span class="value">${value}</span></div>`;

            const previewHtml = `
                <!DOCTYPE html>
                <html lang="es">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Previsualización de Datos</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            background-color: #f4f4f4;
                            margin: 0;
                            padding: 20px;
                        }
                        .container {
                            max-width: 1200px;
                            margin: 0 auto;
                            background: #fff;
                            padding: 20px;
                            border-radius: 8px;
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        }
                        .content-blocks{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            align-items: center;
                            min-width: 70dvw;
                            gap: 4;
                            margin-top: 20px;
                        }
                        .section1{
                        }
                        .section2{
                        }
                        img{
                            border-radius: 100%;
                        }
                        h2 {
                            text-align: center;
                            color: #333;
                        }
                        .data-item {
                            display: flex;
                            justify-content: flex-start;
                            padding: 10px 0;
                            border-bottom: 1px solid #ddd;
                            gap: 8px;
                        }
                        .data-item:last-child {
                            border-bottom: none;
                        }
                        .data-item span {
                            font-weight: bold;
                        }

                        .data-item span.value{
                            color: #001ec5;
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <h2>Previsualización de Datos</h2>
                        ${ payload.photo ? '<div class="content-blocks"><div class="section1"> <img src="https://api-shield.upn164.edu.mx/pictures/thumbs/'+payload.photo+'" alt="" className="photo w-full" /></div>':''}
                        <div class="section2">
                            ${valueRow("Nombre", `${pseudo ? pseudo + "." : ""} ${firstname} ${lastname} ${surname}`.toUpperCase())}
                            ${valueRow("Correo", `${email}`.toLowerCase())}
                            ${valueRow("Fecha de Nacimiento", `${birthdate}`.toLowerCase())}
                            ${valueRow("Curp", `${dni}`.toUpperCase())}
                            ${valueRow("matricula", `${enrollment}`.toUpperCase())}
                            ${valueRow("Carrera", `${career}`.toUpperCase())}
                            ${valueRow("Grupo", `${group}`.toUpperCase())}
                            ${valueRow("RFC", `${rfc}`.toUpperCase())}
                            ${valueRow("Contácto de Emergencia", `${emergencyContactName} - ${emergency_phone}`.toUpperCase())}
                            ${valueRow("sexo", `${sexo}`.toUpperCase())}
                            ${valueRow("nss", `${nss}`.toUpperCase())}
                            ${valueRow("Teléfono", `${personal_phone}`.toUpperCase())}
                            ${valueRow("Domicilio", `${homeAddress}, ${cityAddress}, ${stateAddress}, ${cpAddress}`.toUpperCase())}
                            ${valueRow("Es Donador?", `${isDonor}`.toUpperCase())}
                            ${valueRow("Tipo de sangre", `${bloodType}`.toUpperCase())}
                        </div>
                        <div style="display:flex; flex-direction: column; justify-content: center; align-items: center; flex-gap:2;">
                            <h1 style="color: #d70000; padding: 0; margin: 0;">¿Es correcta la información?</h1>
                            <h2 style="color: #0450c5; padding: 0;margin: 0;">Presione cualquier tecla para cerrar esta ventana</h2>
                        </div>
                    </div>
                    <script>
                        // Agregar listener de teclado para cerrar la ventana
                        document.addEventListener('keydown', function() {
                            event.preventDefault();
                            event.stopPropagation();
                            window.close();
                        });
                    </script>
                </body>
                </html>
            `;

            const availWidth = window.screen.availWidth;
            const availHeight = window.screen.availHeight;

            const previewWindow: any = window.open('', '_blank', 'width=' + availWidth + ',height=' + availHeight);
            previewWindow.document.write(previewHtml);
            previewWindow.document.close();
            previewWindow.moveTo(0, 0);
            previewWindow.resizeTo(availWidth, availHeight);
            resolve(true);
        })
    }, [])

    return {
        userDataPreview,
        userConfirmationRegisterDataPreview
    };
};

export default useWindowDataPreview;
