import { useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import Frontal from "../../../../../assets/images/design/NewDesign/FrontFixedV2.jpg";
import CardHandleInterface from '../../interfaces/CardHandleInterface';
import FrontCardInterfaceProps from '../../interfaces/FrontCardInterfaceProps';
import FrontCardDataInterface from '../../interfaces/FrontCardDataInterface';
import './styles.css';

interface FrontalCardPropsInterface {
    data?: any;
    photo?: string | undefined;
    sign?: string | undefined;
    codebar?: string | undefined;
    refrendoBlocks?: Array<any> | undefined;
    qrCode?: string | undefined;
    referenceGuides?: boolean
}

const debug: boolean = false;

const textColor: string = debug ? "red" : "#fff";

const FrontalCard = forwardRef<CardHandleInterface, FrontCardInterfaceProps>((props, ref) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { data, photo, sign, codebar, refrendoBlocks, qrCode, referenceGuides = true }: FrontalCardPropsInterface = props;
    const { cardID, tipo, nombre, subnombre, matricula, carrera }: FrontCardDataInterface = data;

    useImperativeHandle(ref, () => ({
        getBase64Image: () => {
            // console.log(canvasRef, canvasRef.current);

            return canvasRef.current?.toDataURL('image/png') ?? '';
        }
    }));

    const drawText = (ctx: CanvasRenderingContext2D | null, text: string = "", x: number = 0, y: number = 0, color: string = "#000", fontSize: number = 40, rotate: number = 0) => {
        if (ctx) {
            ctx.save(); // Guardar el estado del contexto
            ctx.fillStyle = color; // Color del texto
            ctx.font = `bold ${fontSize}px Poppins`; // Tamaño y fuente del texto
            if (rotate === -1 || rotate === 1) {
                ctx.translate(x, y); // Mover el origen del sistema de coordenadas
                ctx.rotate((rotate * Math.PI) / 2); // Rotar el canvas 90 grados en sentido horario
                ctx.fillText(text, 0, 0); // Dibujar el texto en la nueva posición
            } else {
                ctx.fillText(text, x, y); // Dibujar el texto en la posición original
            }
            ctx.restore(); // Restaurar el estado del contexto
        }
    }

    useEffect(() => {
        const loadFonts = async () => {
            await document.fonts.load('bold 40px Poppins');
        };

        const drawCanvas = () => {
            if (canvasRef.current) {
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');

                if (context) {
                    const scale = window.devicePixelRatio;
                    const width = 1296; //648
                    const height = 2100; //1100
                    canvas.width = width * scale;
                    canvas.height = height * scale;
                    canvas.style.width = `${width}px`;
                    canvas.style.height = `${height}px`;
                    context.scale(scale, scale);

                    const image = new Image();
                    image.src = Frontal;
                    image.onload = () => {
                        context.clearRect(0, 0, canvas.width, canvas.height); // Limpiar el canvas
                        context.drawImage(image, 0, 0, width, height);

                        let subnombreValue = subnombre ? String(subnombre.value).trim() : "";
                        let hasSubnombre = subnombreValue !== "";
                        
                        // Pintado de los textos:
                        // drawText(context, tipo ? tipo.value : "", (315 + (tipo ? tipo.x : 0)), (-310 + (tipo ? tipo.y : 0)), textColor, 42, 0);
                        drawText(context, tipo ? (tipo.value.toUpperCase()) : "", (155 + (tipo ? tipo.x : 0)), (1380 + (tipo ? tipo.y : 0)), textColor, 124, -1);

                        drawText(context, nombre ? ((nombre.value + "").toUpperCase()) : "", (324 + (nombre ? nombre.x : 0)), (1080 + (nombre ? nombre.y : 0)), textColor, 70);
                        
                        if(hasSubnombre){
                            drawText(context, subnombre ? ((subnombre.value + "").toUpperCase()) : "", (324 + (subnombre ? subnombre.x : 0)), (1160 + (subnombre ? subnombre.y : 0)), textColor, 65);
                        }

                        let carreraLenght: number = (carrera && carrera.value) ? (carrera?.value)?.length : 0;

                        let aditionalAddFontSize = 0;

                        if(carreraLenght >=20 && carreraLenght <=32){
                            aditionalAddFontSize+= 10;
                        }else if(carreraLenght >= 33 && carreraLenght <= 37){
                            aditionalAddFontSize-= 5;
                        } else if (carreraLenght >= 38){
                            aditionalAddFontSize-= 10;
                        }
                        

                        drawText(context, (carrera ? carrera.value : ""), (460 + (carrera ? carrera.x : 0)), (1230 + (carrera ? carrera.y : 0)), textColor, (aditionalAddFontSize + 55));


                        drawText(context, ("ID/Matricula: " + (matricula ? matricula.value : "")), (300 + (matricula ? matricula.x : 0)), (1312 + (matricula ? matricula.y : 0)), textColor, 68);

                        if (refrendoBlocks) {
                            const monthLimit = (refrendoBlocks[refrendoBlocks.length - 2].period).split("-")[1];
                            const yearLimit = (refrendoBlocks[refrendoBlocks.length - 2].year);
                            drawText(context, (monthLimit + " - " + yearLimit).toUpperCase(), 555, 2040, textColor, 72, 0);//TODO: create funcionality
                        }

                        if (qrCode) {
                            const qrCodeImage = new Image();
                            qrCodeImage.src = qrCode;
                            qrCodeImage.onload = () => {
                                context.drawImage(qrCodeImage, 505, 1370, 470, 470);
                            }
                        }

                        if (photo) {
                            const userPhoto = new Image();
                            const photoWidth = 620;
                            const photoHeight = 621;
                            userPhoto.src = photo;
                            userPhoto.onload = () => {
                                context.drawImage(userPhoto, 420, 309, photoWidth, photoHeight);
                            }
                        }

                        drawText(context, cardID ? "Noº  " + cardID.value : "", (1185 + (cardID ? cardID.x : 0)), (1565 + (cardID ? cardID.y : 0)), textColor, 68, 1);
                        // if (sign) {
                        //     context.save();
                        //     const userSign = new Image();
                        //     const signWidth = 608;
                        //     const signHeight = 240;
                        //     userSign.src = sign;
                        //     userSign.onload = () => {
                        //         context.translate(480, 1540); // Mover el origen del sistema de coordenadas
                        //         context.rotate((1 * Math.PI) / 2); // Rotar el canvas 90 grados en sentido horario
                        //         // context.fillText(text, 0, 0); // Dibujar el texto en la nueva posición
                        //         context.drawImage(userSign, 0, 0, signWidth, signHeight);
                        //     }
                        //     context.restore();
                        // }
                    };
                }
            }
        };

        loadFonts().then(drawCanvas);
    }, [cardID, tipo, nombre, subnombre, matricula, carrera, codebar, photo, sign, refrendoBlocks, qrCode]);

    return <div className="cardCanvasRender">
        {
            referenceGuides && <div className='guidesContainer'>
                <div className="lineX"></div>
                <div className="lineY"></div>
                <div className="sublineYL"></div>
                <div className="sublineYR"></div>
            </div>
        }
        <canvas ref={canvasRef} />
    </div>;
});

export default FrontalCard;
