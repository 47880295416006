import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import logo from "../../../assets/images/logo.svg";

/**
 * FullFailBackScreen properties interface
 */
interface FullFailBackScreenProperties {
    message?: string;
}

/**
 * FullFailBackScreen component
 * @param {FullFailBackScreenProperties} props - Component props
 * @returns {JSX.Element}
 */
const FullFailBackScreen:React.FC<FullFailBackScreenProperties> = ({message = "Cargando..."}): JSX.Element => {
    const loadingMessage = message.split("");
    return <div className={[style.fullFailBackScreenComponent, "w-full h-full top-0 left-0 absolute text-white text-xl flex flex-col items-center justify-center"].join(" ")}>
        <div className="login-logo-image hidden lg:flex text-center view show items-center justify-center">
            <div className={[style.block1].join(" ")}>
                <img src={logo} alt="logotipo-app" className="w-4/6" />
            </div>
            <h1 className={["font-bold flex flex-col justify-start items-start ml-4"].join(" ")}>
                <span className="text-6xl">SIA</span>
                <span className="text-5xl">Administrator System</span>
            </h1>
        </div><div className={[style.block2, "mt-2"].join(" ")}>
            <div className={style.loader}></div>
        </div>
        <div className={[style.block3, "mt-4"].join(" ")}>
            {
                loadingMessage.map((item: string, key: number) => {
                    return <span className="text-2xl text-white" key={key}>{item}</span>
                })
            }  
        </div>
        
    </div>;
};

FullFailBackScreen.propTypes = {
    message: PropTypes.string,
};

FullFailBackScreen.defaultProps = {
    message: "Cargando...",
};

export default FullFailBackScreen;
