import React, { useCallback, useEffect, useRef, useState } from 'react';

const useZoomValue = (defaultZoomValue: number) => {
    const [zoom, setZoom] = useState<number>(defaultZoomValue);

    // Función para manejar el zoom in
    const zoomIn = useCallback(() => {
        setZoom((prevZoom: number) => parseFloat((prevZoom + 0.1).toFixed(2)));
    }, []);

    // Función para manejar el zoom out
    const zoomOut = useCallback(() => {
        setZoom((prevZoom: number) => parseFloat(Math.max(prevZoom - 0.1, 0.1).toFixed(2)));
    }, []);

    return {
        zoom,
        zoomIn,
        zoomOut,
        setZoom
    }
}

interface CanvasMaskedPhotoComponentInterface {
    rounded?: boolean,
    darkMode?: boolean,
    base64Image?: boolean,
    image: any,//File | undefined | null,
    onCancel?: () => void,
    onConfirm?: (imageDataUrl: string) => void,
}

const useImageDimensions = (defaultWidth: number = 250, defaultHeight: number = 250) => {
    const [imageWidth, setImageWidth] = useState<number>(defaultWidth);
    const [imageHeight, setImageHeight] = useState<number>(defaultHeight);

    const increaseWidthImage = useCallback(() => {
        setImageWidth((prevValue: number): number => prevValue + 10);
    }, []);

    const restoreImageWidth = useCallback(() => {
        setImageWidth(defaultWidth);
    }, [defaultWidth]);

    const decreaseImageWidth = useCallback(() => {
        setImageWidth((prevZoom: number) => Math.max(prevZoom - 10, 1));
    }, []);

    const increaseHeightImage = useCallback(() => {
        setImageHeight((prevValue: number): number => prevValue + 10);
    }, []);

    const restoreImageHeight = useCallback(() => {
        setImageHeight(defaultHeight);
    }, [defaultHeight]);

    const decreaseImageHeight = useCallback(() => {
        setImageWidth((prevZoom: number) => Math.max(prevZoom - 10, 1));
    }, []);

    return {
        imageWidth,
        increaseWidthImage,
        decreaseImageWidth,
        restoreImageWidth,
        setImageWidth,

        imageHeight,
        increaseHeightImage,
        decreaseImageHeight,
        restoreImageHeight,
        setImageHeight
    }
}

const useAxisValues = (defaultXValue: number = 0, defaultYValue: number = 0) => {
    const [x, setX] = useState<number>(defaultXValue);
    const [y, setY] = useState<number>(defaultYValue);

    return {
        x,
        setX,
        y,
        setY,
    }
}

const CanvasMaskedPhotoComponent = ({ darkMode, image, base64Image, onCancel, onConfirm, rounded = false }: CanvasMaskedPhotoComponentInterface) => {
    const [maskSize] = useState<number>(250);
    const [withMask, setWithMask] = useState<boolean>(rounded);
    const [imageBrightness, setImageBrightness] = useState<number>(100);
    const [imageContrast, setImageContrast] = useState<number>(100);

    const {
        imageWidth,
        restoreImageWidth,
        setImageWidth,
        imageHeight,
        restoreImageHeight,
        setImageHeight
    } = useImageDimensions();

    const { x, setX, y, setY } = useAxisValues();

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasContextRef = useRef<CanvasRenderingContext2D | null>(null);
    const { zoom, setZoom } = useZoomValue(1);
    const [imageComponent, setImageComponent] = useState<HTMLImageElement | null>(null);


    // Agrega estados para el arrastre y las coordenadas del mouse
    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);

    // Funciones para manejar el inicio, movimiento y fin del arrastre
    const handleDragStart = useCallback((event: React.MouseEvent<HTMLCanvasElement>) => {
        setDragging(true);
        setStartX(event.clientX - event.currentTarget.getBoundingClientRect().left);
        setStartY(event.clientY - event.currentTarget.getBoundingClientRect().top);
    }, []);

    const handleDragMove = useCallback((event: React.MouseEvent<HTMLCanvasElement>) => {
        if (!dragging) return;
        const x = event.clientX - event.currentTarget.getBoundingClientRect().left - startX;
        const y = event.clientY - event.currentTarget.getBoundingClientRect().top - startY;
        setX(x);
        setY(y);
    }, [startX, startY, dragging, setX, setY]);

    const handleDragEnd = useCallback(() => {
        setDragging(false);
    }, [setDragging]);

    const resetImageProps = useCallback(() => {
        setZoom(1);
        restoreImageWidth();
        restoreImageHeight();
        setStartX(0);
        setStartY(0);
        setX(0);
        setY(0);
    }, [setZoom, restoreImageHeight, restoreImageWidth, setStartX, setStartY, setX, setY]);

    useEffect(() => {        
        if (image) {
            setZoom(1)
            setImageComponent(null)

            const canvas = canvasRef.current;
            if (!canvas) return;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            canvasContextRef.current = ctx;
            ctx.clearRect(0, 0, maskSize, maskSize);
        }
    }, [image, maskSize, setZoom]);

    useEffect(() => {
        if (!image) return;
        const img = new Image();
        if (!imageComponent && image) {

            if (image) {
                const imageUrl = !base64Image ? URL.createObjectURL(image) : image;
                img.crossOrigin = 'anonymous';
                img.onload = () => {
                    setImageComponent(img);
                    resetImageProps();
                };
                img.src = imageUrl;
            }
        }
        return () => {
            URL.revokeObjectURL(img.src);
        };
    }, [image, imageComponent, resetImageProps, base64Image]);

    // Agrega los manejadores de eventos al canvas
    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const handleMouseDown = (event: any) => handleDragStart(event);
        const handleMouseMove = (event: any) => handleDragMove(event);
        const handleMouseUp = () => handleDragEnd();

        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);

        return () => {
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);
        };
    }, [canvasRef, dragging, handleDragStart, handleDragMove, handleDragEnd]);

    useEffect(() => {
        const canvasContext = canvasContextRef.current;
        const image = imageComponent;
        if (!canvasContext || !image) return;

        canvasContext.clearRect(0, 0, maskSize, maskSize);

        // Calcular las dimensiones de la imagen con el zoom
        const scaledWidth = imageWidth * zoom;
        const scaledHeight = imageHeight * zoom;
        const offsetX = (maskSize - scaledWidth) / 2;
        const offsetY = (maskSize - scaledHeight) / 2;

        canvasContext.filter = `
            brightness(${imageBrightness}%)
            contrast(${imageContrast}%)
        `;

        // 
            // ${filters.value !== 'none' ? filters.value + '(1)' : ''}

        // Dibujar la imagen escalada con el zoom
        canvasContext.drawImage(image, offsetX + x, offsetY + y, scaledWidth, scaledHeight);

        // Aplicar máscara circular
        canvasContext.globalCompositeOperation = 'destination-atop';
        canvasContext.beginPath();
        if (withMask) {
            canvasContext.arc(maskSize / 2, maskSize / 2, maskSize / 2, 0, Math.PI * 2);
        }
        canvasContext.closePath();
        canvasContext.fill();

    }, [imageComponent, canvasContextRef, zoom, maskSize, imageWidth, imageHeight, x, y, withMask, imageBrightness, imageContrast]);

    const handleZoomChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        setZoom(value);
    }, [setZoom]);

    const handleImageWidthChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setImageWidth(value);
    }, [setImageWidth]);

    const handleImageHeightChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setImageHeight(value);
    }, [setImageHeight]);

    const handleImageBrightnessChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setImageBrightness(value);
    }, [setImageBrightness]);

    const handleImageContrastChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setImageContrast(value);
    }, [setImageContrast]);

    // const handleImageXChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = parseInt(event.target.value);
    //     setX(value);
    // }, [setX]);

    // const handleImageYChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = parseInt(event.target.value);
    //     setY(value);
    // }, [setY]);

    const handleOnCancel = () => {
        setWithMask(false);
        setZoom(1);
        setImageComponent(null);
        resetImageProps();
        if (onCancel) {
            onCancel();
        }
    }

    const handleConfirm = useCallback(() => {
        if (canvasRef.current) {
            // Obtener la imagen del canvas en formato PNG
            const canvasImageURL = canvasRef.current.toDataURL('image/png');
            // Si necesitas retornar esta URL a través de la función onConfirm prop
            if (onConfirm) {
                onConfirm(canvasImageURL);
            }
        }
    }, [onConfirm]);

    return <div className={"CanvasPhotoEditor " + (darkMode && "dark")}>
        {image &&
            <>
                <div className="imageContainer">
                    <canvas
                        ref={canvasRef}
                        width={maskSize}
                        height={maskSize}
                    />
                    <div className="slice-controller">
                        <input
                            id="zoom-input"
                            type="range"
                            min="0.1"
                            max="5"
                            step="0.1"
                            value={zoom}
                            onChange={handleZoomChange}
                        />
                        <label htmlFor="zoom-input">Zoom: {zoom}</label>
                    </div>
                </div>
                <div className="controls">
                    <div className="imageSizing">
                        <div className="slice-controller">
                            <input
                                id="width-input"
                                type="range"
                                min="10"
                                max="1000"
                                step="10"
                                value={imageWidth}
                                onChange={handleImageWidthChange}
                            />
                            <label htmlFor="zoom-input">Ancho {imageWidth}</label>
                        </div>
                        <div className="slice-controller">
                            <input
                                id="height-input"
                                type="range"
                                min="10"
                                max="1000"
                                step="10"
                                value={imageHeight}
                                onChange={handleImageHeightChange}
                            />
                            <label htmlFor="zoom-input">Alto {imageHeight}</label>
                        </div>
                        <div className="slice-controller" style={{marginTop: "20px"}}>
                            <input
                                id="height-input"
                                type="range"
                                min="-100" max="200"
                                step="5"
                                value={imageBrightness}
                                onChange={handleImageBrightnessChange}
                            />
                            <label htmlFor="zoom-input">Brillo {imageBrightness}</label>
                        </div>
                        <div className="slice-controller" style={{margin: "20px 0"}}>
                            <input
                                id="height-input"
                                type="range"
                                min="-100" max="200"
                                step="5"
                                value={imageContrast}
                                onChange={handleImageContrastChange}
                            />
                            <label htmlFor="zoom-input">Contraste {imageContrast}</label>
                        </div>
                    </div>

                    <div className="check-toggleMask">
                        <input type="checkbox" className="toggleMaskCircled" id="toggleMaskCircled" checked={withMask} onChange={() => { setWithMask((current) => !current) }} />
                        <label htmlFor="toggleMaskCircled">Redondeado</label>
                    </div>

                    <div className="container-action-controls">
                        <button className="generic-button confirm" onClick={handleConfirm}>Confirmar</button>
                        <button className="generic-button cancel" onClick={handleOnCancel}>Cancelar</button>
                    </div>
                </div>
            </>
        }
    </div>
}

export default CanvasMaskedPhotoComponent;