import React from 'react'
import classnames from 'classnames'
import './style.scss'
const AnimatedActionCircle = (props) => {
  let type = props.type ? props.type : "info";

  let typeContent = null


  switch (type) {
    case 'info':
        typeContent = <span className="animated-icon-text">i</span>
    break;
    case 'error':
        typeContent = <span className="animated-icon-text">X</span>
    break;
    case 'success':
        typeContent = <span className="animated-icon-text">✓</span>
    break;
    case 'warning':
        typeContent = <span className="animated-icon-text">!</span>
    break;
    case 'question':
    default:
        typeContent = <span className="animated-icon-text">?</span>
    break;
  }

  return (
    <div className="animation-info">
      <div className={classnames("animated-icon", "animated-"+type )}>
        {
          typeContent
        }
      </div>
    </div>
  )
}
export default AnimatedActionCircle
