import { useRef, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';
import Trasera from "../../../../../assets/images/design/NewDesign/BackV2.jpg";

import CardHandleInterface from '../../interfaces/CardHandleInterface';
import BackCardDataInterface from '../../interfaces/BackCardDataInterface';

interface BackCardDataInterfaceProps {
    data?: any;
    codebar?: any;
    refrendoBlocks?: Array<any>;
    validationURL?: string | null | undefined;
}

const debug: boolean = false;

const textColor: string = debug ? "red" : "#000";

const TraseraCard = forwardRef<CardHandleInterface, BackCardDataInterfaceProps>((props, ref) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const { data, codebar, refrendoBlocks, validationURL }: BackCardDataInterfaceProps = props;
    const {
        curp,
        telefono,
        correo,
        direccion,
        ciudad,
        estado,
        cp,
        telefono_emergencia,
        nombre_emergencia,
        nss,
        sangre,
        donador
    }: BackCardDataInterface = data;

    useImperativeHandle(ref, () => ({
        getBase64Image: () => {
            if (canvasRef.current) {
                return canvasRef.current.toDataURL("image/png");
            }
            return '';
        }
    }));

    const drawText = (ctx: CanvasRenderingContext2D | null, text: string = "", x: number = 0, y: number = 0, color: string = "#000", fontSize: number = 40, rotate: number = 0, weight?: boolean) => {
        if (ctx) {
            ctx.save();
            ctx.fillStyle = color;
            ctx.font = `${weight ? "bold" : ""} ${fontSize}px Poppins`;
            if (rotate === -1 || rotate === 1) {
                ctx.translate(x, y);
                ctx.rotate((rotate * Math.PI) / 2);
                ctx.fillText(text, 0, 0);
            } else {
                ctx.fillText(text, x, y);
            }
            ctx.restore();
        }
    }

    const getLines = useCallback((ctx: any, text: string, maxWidth: number) => {
        const words = text.split(' ');
        const lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const width = ctx.measureText(currentLine + " " + word).width;
            if (width < maxWidth) {
                currentLine += " " + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);
        return lines;
    }, []);

    const createRefrendoBlockContent = useCallback((context: any, item: any, axisY: number = 0) => {
        if (context && item) {
            drawText(context, item && item.period ? item.period : "", 160, 280 + axisY, textColor, 36, 1, true);
            drawText(context, item && item.year ? item.year : "", 100, 305 + axisY, textColor, 42, 1, true);
        }
    }, []);

    useEffect(() => {
        const loadFonts = async () => {
            const fontPromises = [
                document.fonts.load('bold 40px Poppins'),
                document.fonts.load('normal 40px Poppins')
            ];
            await Promise.all(fontPromises);
        };

        const drawCanvas = () => {
            if (canvasRef.current) {
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');
                if (context) {
                    const scale = window.devicePixelRatio;
                    const width = 1296;
                    const height = 2200;
                    canvas.width = width * scale;
                    canvas.height = height * scale;
                    canvas.style.width = `${width}px`;
                    canvas.style.height = `${height}px`;
                    context.scale(scale, scale);

                    const direccionValue: string = `${direccion && direccion.value}, ${ciudad && ciudad.value}, ${estado && estado.value}, ${cp && cp.value}`;
                    const maxWidth = 220;
                    const lineHeight = 44;
                    const lines = getLines(context, direccionValue, maxWidth);

                    const image = new Image();
                    image.src = Trasera;

                    image.onload = () => {
                        context.drawImage(image, 0, 0, width, height);

                        if (data) {
                            drawText(context, correo && correo.value ? (correo.value.toLowerCase()) : "", (320 + (correo ? correo.x : 0)), (300 + (correo ? correo.y : 0)), textColor, 38, 0, true);
                            drawText(context, curp && curp.value ? (curp.value.toUpperCase()) : "", (460 + (correo ? correo.x : 0)), (375 + (correo ? correo.y : 0)), textColor, 44, 0, true);
                            drawText(context, telefono && telefono.value ? (telefono.value.toUpperCase()) : "", (535 + (correo ? correo.x : 0)), (465 + (correo ? correo.y : 0)), textColor, 46, 0, true);

                            let y = 594;
                            for (const line of lines) {
                                drawText(context, line ? (line + "").toUpperCase() : "", 320, y, textColor, 34, 0, true);
                                y += lineHeight;
                            }

                            drawText(context, nss && nss.value ? (nss.value.toUpperCase()) : "", (430 + (nss ? nss.x : 0)), (745 + (nss ? nss.y : 0)), textColor, 47, 0, true);
                            drawText(context, sangre && sangre.value ? (sangre.value.toUpperCase()) : "", (670 + (sangre ? sangre.x : 0)), (826 + (sangre ? sangre.y : 0)), textColor, 47, 0, true);
                            drawText(context, donador && donador.value ? (donador.value.toUpperCase()) : "", (640 + (donador ? donador.x : 0)), (900 + (donador ? donador.y : 0)), textColor, 47, 0, true);

                            const emergency = `${nombre_emergencia?.value + " - " + telefono_emergencia?.value}`;
                            drawText(context, emergency && emergency ? (emergency.toUpperCase()) : "", (322 + (nombre_emergencia ? nombre_emergencia.x : 0)), (1040 + (nombre_emergencia ? nombre_emergencia.y : 0)), textColor, 40, 0, true);

                            if (refrendoBlocks && refrendoBlocks.length === 9) {
                                createRefrendoBlockContent(context, refrendoBlocks[0], 0);
                                createRefrendoBlockContent(context, refrendoBlocks[1], 200);
                                createRefrendoBlockContent(context, refrendoBlocks[2], 395);
                                createRefrendoBlockContent(context, refrendoBlocks[3], 595);
                                createRefrendoBlockContent(context, refrendoBlocks[4], 800);
                                createRefrendoBlockContent(context, refrendoBlocks[5], 998);
                                createRefrendoBlockContent(context, refrendoBlocks[6], 1195);
                                createRefrendoBlockContent(context, refrendoBlocks[7], 1396);
                            }

                            if (codebar) {
                                const codebarImage: any = new Image();
                                codebarImage.src = codebar;
                                codebarImage.onload = () => {
                                    context.drawImage(codebarImage, 300, 1630, 900, 280);
                                };
                            }

                            if (validationURL) {
                                drawText(context, validationURL, 100, 2120, textColor, 38, 0, true);
                            }
                        }
                    };
                }
            }
        };

        loadFonts().then(drawCanvas);
    }, [data, ciudad, cp, direccion, estado, curp, getLines, telefono, correo, donador, sangre, nss, nombre_emergencia, telefono_emergencia, createRefrendoBlockContent, refrendoBlocks, codebar, validationURL]);

    return <canvas ref={canvasRef} />;
});

export default TraseraCard;
