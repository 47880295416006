import React from 'react';
import PropTypes from 'prop-types';

/**
 * MainContentSection properties interface
 */
interface MainContentSectionProperties {
    children?: React.ReactNode;
    title?: string
}

/**
 * MainContentSection component
 * @param {MainContentSectionProperties} props - Component props
 * @returns {JSX.Element}
 */
const MainContentSection: React.FC<MainContentSectionProperties>= ({children, title}): JSX.Element => {
    return <div className="body w-full h-full px-6 overflow-y-auto pb-20">
        <section className="screen-title border-b-gray-500 border-b-2 w-full h-14 flex justify-start items-center">
            <h2 className="text-2xl font-sans my-2 font-bold text-white">{title}</h2>
        </section>

        <section className="content w-full">
            {children}
        </section>
    </div>
};

MainContentSection.propTypes = {
    children: PropTypes.node,
};

MainContentSection.defaultProps = {
    children: null,
};

export default MainContentSection;
