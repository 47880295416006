import React from 'react'

import './style.scss'
const AnimationErrorCircle = (props) => {
  return (<div className="animation-error">
    <div className="swal2-icon swal2-error swal2-animate-error-icon" style={{display: 'flex'}}>
      <span className="swal2-x-mark">
        <span className="swal2-x-mark-line-left"></span>
        <span className="swal2-x-mark-line-right"></span >
      </span>
    </div >
  </div>)
}

export default AnimationErrorCircle
