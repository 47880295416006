import React, { useRef, useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import styles from './style.module.css';

interface QRCodeGeneratorProps {
    type: 'wifi' | 'wifiHidden' | 'phone' | 'vcard' | 'text' | 'url' | 'email';
    ssid?: string;
    password?: string;
    email?: string;
    hidden?: boolean;
    showCode?: boolean;
    phoneNumber?: string;
    vcardData?: string;
    text?: string;
    url?: string;
    onBase64Generated?: (base64: string) => void;
    size?: number;
    previewSize?: number;
    downloadable?: boolean;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({
    type,
    ssid,
    password,
    hidden = false,
    phoneNumber,
    vcardData,
    text,
    url,
    email,
    onBase64Generated,
    showCode,
    size = 512,
    previewSize = 200,
    downloadable = false,
}) => {
    const qrRef = useRef<HTMLDivElement>(null);
    const [base64, setBase64] = useState<string>('');

    useEffect(() => {
        const canvas = qrRef.current?.querySelector('canvas');
        if (canvas) {
            const base64Image = canvas.toDataURL('image/png', 1.0); // quality set to 1.0
            setBase64(base64Image);
            if (onBase64Generated) {
                onBase64Generated(base64Image);
            }
        }
    }, [type, ssid, password, hidden, phoneNumber, vcardData, text, url, email, onBase64Generated]);

    const handleClick = () => {
        if (!qrRef.current || !downloadable) return;
        const canvas = qrRef.current.querySelector('canvas');
        if (canvas) {
            const base64Image = canvas.toDataURL('image/png', 1.0); // quality set to 1.0
            const link = document.createElement('a');
            link.href = base64Image;
            link.download = `qrcode-${type}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    let qrValue = '';

    switch (type) {
        case 'wifi':
            qrValue = `WIFI:T:WPA;S:${ssid};P:${password};;`;
            break;
        case 'wifiHidden':
            qrValue = `WIFI:T:WPA;S:${ssid};P:${password};H:${hidden ? 'true' : 'false'};;`;
            break;
        case 'phone':
            qrValue = `tel:${phoneNumber}`;
            break;
        case 'vcard':
            qrValue = vcardData || '';
            break;
        case 'text':
            qrValue = text || '';
            break;
        case 'url':
            qrValue = url || '';
            break;
        case 'email':
            qrValue = `mailto:${email}` || '';
            break;
        default:
            qrValue = '';
    }

    const scale = previewSize / size;

    return (
        <div className={`${styles.QRCodeContainer}`}>
            <div ref={qrRef} style={{ backgroundColor:"#fff", padding: "6px", overflow: "hidden", width: `${previewSize}px`, height: `${previewSize}px`, cursor: downloadable ? 'pointer' : 'default' }} onClick={handleClick}>
                <QRCode className="SkipStyle" value={qrValue} size={size} level="H" style={{ transform: `scale(${scale}`, transformOrigin: '0 0' }} />
            </div>
            {showCode && base64 && (
                <div>
                    <h3>Base64 String:</h3>
                    <textarea rows={4} cols={50} readOnly value={base64} />
                </div>
            )}
        </div>
    );
};

export default QRCodeGenerator;
