/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

const useLocalStorage = () => {

    // Get a specific value from localStorage
    const getLS = useCallback((key: string, type: "json" | "string" | number = "string"): string | {key: string} | any | undefined => {
        try {
            const storedValue = localStorage.getItem(key);
            if(type==="json"){
                return storedValue ? JSON.parse(storedValue) : undefined;
            }
            return storedValue;
        } catch (error) {
            console.error(`Error getting key "${key}" from localStorage:`, error);
            return undefined;
        }
    }, []);

    // Set a specific value in localStorage
    const setLS = useCallback((key: string, value: any, sourceType: "json" | "string" = "string"): void => {
        try {
            if(sourceType === "json"){
                localStorage.setItem(key, JSON.stringify(value));
                return
            }
            localStorage.setItem(key, value);
            return
        } catch (error) {
            console.error(`Error setting key "${key}" in localStorage:`, error);
        }
    }, []);

    // Get all keys and values from localStorage as an object
    const getAllLS = useCallback((): Record<string, any> => {
        const entries: Record<string, any> = {};
        try {
            const keys = Object.keys(localStorage);
            keys.forEach((key) => {
                const value = localStorage.getItem(key);
                if (value) {
                    entries[key] = JSON.parse(value);
                }
            });
        } catch (error) {
            console.error('Error getting all keys from localStorage:', error);
        }
        return entries;
    }, []);

    // Clear all keys and values in localStorage
    const clearLS = useCallback((): void => {
        try {
            localStorage.clear();
        } catch (error) {
            console.error('Error clearing localStorage:', error);
        }
    }, []);

    // Remove a specific key from localStorage
    const removeLS = useCallback((key: string): void => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error(`Error removing key "${key}" from localStorage:`, error);
        }
    }, []);

    // Check if a specific key exists in localStorage
    const existsLS = useCallback((key: string): boolean => {
        try {
            return localStorage.getItem(key) !== null;
        } catch (error) {
            console.error(`Error checking existence of key "${key}" in localStorage:`, error);
            return false;
        }
    }, []);

    return {
        getLS,
        setLS,
        getAllLS,
        clearLS,
        removeLS,
        existsLS,
    };
};

export default useLocalStorage;
