const humanizeDateString = (dateString: string, datetimeSeparator: string = ", ", capitalTransform: "upper" | "lower" = "upper") => {
    const date = new Date(dateString);

    // Obtener día de la semana
    const dayOfWeek = new Intl.DateTimeFormat('es-ES', { weekday: 'long' }).format(date);

    // Obtener día del mes
    const day = new Intl.DateTimeFormat('es-ES', { day: 'numeric' }).format(date);

    // Obtener mes
    const month = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(date);

    // Obtener año
    const year = new Intl.DateTimeFormat('es-ES', { year: 'numeric' }).format(date);

    // Obtener hora en formato 12 horas
    const time = new Intl.DateTimeFormat('es-ES', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    }).format(date);

    if(capitalTransform === "lower"){
       return (`${dayOfWeek} ${day} de ${month} de ${year}${datetimeSeparator}${time}`).toLowerCase()
    }

    return (`${dayOfWeek} ${day} de ${month} de ${year}${datetimeSeparator}${time}`).toUpperCase();
}

export {
    humanizeDateString
}