import { useCallback, useEffect, useRef, useState } from 'react';
import CardHandleInterface from './interfaces/CardHandleInterface';
import FrontCardDataInterface from './interfaces/FrontCardDataInterface';
import InputCardMaker from './CardMakerComponents/InputCardMaker';
import InputXYCardMaker from './CardMakerComponents/InputXYCardMaker';
import BarcodeCanvas from './components/BarcodeCanvas/BarcodeCanvas';
import PictureIdComponent from '../PictureIDComponent';
import BackCardDataInterface from './interfaces/BackCardDataInterface';
import IDCardMakerProps from './interfaces/IDCardMakerProps';
import IDCardMakerDataInterface from './interfaces/IDCardMakerDataInterface';
import DefaultIDDataPropModel from './models/DefaultIDDataPropModel';
import FormStepper from '../FormStepper';

import FrontalCard from './components/NewVersionCard/FrontalCard';
import TraseraCard from './components/NewVersionCard/TraseraCard';

import './style.css';
import QRCodeGenerator from './components/QRCodeGenerator';

const VALIDATION_URL = `https://myID.upn164.edu.mx/checkID`;

const generatePeriods = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Los meses son 0-indexed
    const periods = [];
    const numYears = 4.5; // 4 años y medio

    // Determinar el periodo actual
    let startPeriodIndex;
    if (currentMonth >= 1 && currentMonth <= 6) {
        startPeriodIndex = 0; // Ene-Jul
    } else {
        startPeriodIndex = 1; // Ago-Dic
    }

    for (let i = 0; i < numYears * 2; i++) {
        const year = currentYear + Math.floor((i + startPeriodIndex) / 2);
        const period = (i + startPeriodIndex) % 2 === 0 ? "Ene-Jul" : "Ago-Dic";

        periods.push({ year, period });
    }

    return periods;
};

const refrendoBlocks = generatePeriods();

const data_constructor = (data: IDCardMakerDataInterface) => {
    const transformed: any = {
        front: {
            cardID: new DefaultIDDataPropModel(data?.front?.cardID ? data?.front?.cardID : ""),
            nombre: new DefaultIDDataPropModel(data?.front?.nombre ? data?.front?.nombre : ""),
            subnombre: new DefaultIDDataPropModel(""),
            tipo: new DefaultIDDataPropModel(data?.front?.tipo ? data?.front?.tipo : ""),
            matricula: new DefaultIDDataPropModel(data?.front?.matricula ? data?.front?.matricula : ""),
            carrera: new DefaultIDDataPropModel(data?.front?.carrera ? data?.front?.carrera : ""),
            codebar: new DefaultIDDataPropModel(data?.front?.matricula ? data?.front?.matricula : "---")
        },
        back: {
            curp: new DefaultIDDataPropModel(data?.back?.curp ? data?.back?.curp : ""),
            telefono: new DefaultIDDataPropModel(data?.back?.telefono ? data?.back?.telefono : ""),
            correo: new DefaultIDDataPropModel(data?.back?.correo ? data?.back?.correo : ""),
            direccion: new DefaultIDDataPropModel(data?.back?.direccion ? data?.back?.direccion : ""),
            ciudad: new DefaultIDDataPropModel(data?.back?.ciudad ? data?.back?.ciudad : ""),
            estado: new DefaultIDDataPropModel(data?.back?.estado ? data?.back?.estado : ""),
            cp: new DefaultIDDataPropModel(data?.back?.cp ? data?.back?.cp : ""),
            telefono_emergencia: new DefaultIDDataPropModel(data?.back?.telefono_emergencia ? data?.back?.telefono_emergencia : ""),
            nombre_emergencia: new DefaultIDDataPropModel(data?.back?.nombre_emergencia ? data?.back?.nombre_emergencia : ""),
            nss: new DefaultIDDataPropModel(data?.back?.nss ? data?.back?.nss : ""),
            sangre: new DefaultIDDataPropModel(data?.back?.sangre ? data?.back?.sangre : ""),
            donador: new DefaultIDDataPropModel(data?.back?.donador ? data?.back?.donador : ""),
        }
    };
    return transformed;
}

const sendToPrint = (frontShape: any, backShape: any) => {
    // Preparar el contenido HTML con las imágenes en base64
    const htmlContent = `
            <!doctype html>
        <html>
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Impresión de Credencial</title>
            <style>
                @media print {
                    body{
                        padding: 0;
                        margin: 0;
                        //background-color: #003399;
                    }
                    .shape {
                        image-rendering: -webkit-optimize-contrast;
                        image-rendering: crisp-edges;
                        image-rendering: pixelated;
                        page-break-after: always;
                    }

                    .shape.front{
                        overflow: inherit;
                    }

                    .front {
                        position: relative;
                        top: 102px;
                        left: 0px;
                        transform: scaleY(1.10) scaleX(1.11);
                    }

                    .front .shapeImage{
                        position: relative;
                        width: 200px;
                        height: 308px
                    }

                    // .back .shapeImage{
                    //     position: relative;
                    //     width: 200px;
                    //     height: 308px
                    // }

                    .back {
                        position: relative;
                        top: 5px;
                        // left: 0px;
                        transform: scaleY(1.05) scaleX(1.05);
                    }
                }
                * {
                    padding: 0;
                    margin: 0;
                }
                body {
                    //background: #282828;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                }
                .shapesContainer {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    height: fit-content;
                    align-items: flex-start;
                }
                .shape {
                    width: 85mm;
                    height: 54mm;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    padding: 0;
                    margin: 0;
                }
                .front {
                    background-color: orange;
                }
                .back {
                    background-color: red;
                }
                .shapeImage{
                    width: 205px;
                    height: 322px;
                }
                .shapeImageFront{
                    transform: rotate(90deg);
                }
                .shapeImageBack{
                    transform: rotate(-90deg);
                }
            </style>
        </head>
        <body>
            <div class="shapesContainer">
                <div class="shape front">
                    <img src="${frontShape}" class="shapeImage shapeImageFront"/>
                </div>
                <div class="shape back print-page">
                    <img src="${backShape}" class="shapeImage shapeImageBack"/>
                </div>
            </div>
        </body>
        </html>
        `;

    // Abrir una nueva ventana y establecer su contenido al HTML generado
    // Dimensiones de la pantalla para maximizar la ventana emergente
    const width = window.screen.width;
    const height = window.screen.height;

    // Abrir una nueva ventana maximizada
    const printWindow = window.open('', '_blank', `width=${width},height=${height},left=0,top=0`);

    if (printWindow) {
        printWindow.document.open();
        printWindow.document.write(htmlContent);
        printWindow.document.close();

        // Esperar a que el contenido de la ventana se cargue completamente antes de imprimir
        printWindow.onload = function () {
            printWindow.focus(); // Enfocar la ventana emergente para garantizar que el diálogo de impresión aparezca en primer plano
            printWindow.print(); // Iniciar el proceso de impresión
            printWindow.onafterprint = function () {
                printWindow.close(); // Cerrar la ventana emergente después de imprimir
            };
        };
    }
}

const printCard = (FrontImage?: string, BackImage?: string) => {
    // console.log(FrontImage, BackImage);

    // const renders = {
    //     front: frontalRef.current?.getBase64Image() ?? "No image",
    //     back: traseraRef.current?.getBase64Image() ?? "No image"
    // };

    if (!FrontImage || !BackImage) {
        alert("No se pudo enviar la credencial a imprimir");
        return
    }

    const renders = {
        front: FrontImage ?? "No image",
        back: BackImage ?? "No image"
    };

    sendToPrint(renders.front, renders.back);
};


const IDCardMaker = (props: IDCardMakerProps) => {
    const { data, onFinish } = props;
    const dataPayload: any = data_constructor(data);

    const [payloadFrontCard, setPayloadFrontCard] = useState<FrontCardDataInterface>(dataPayload.front);
    const [payloadBackCard, setPayloadBackCard] = useState<BackCardDataInterface>(dataPayload.back);

    const [photo, setPhoto] = useState<string | undefined>(data?.photo);
    const [base64QR, setBase64QR] = useState<string>('');
    const [codebar, setCodebar] = useState<string | undefined>(undefined);

    const [customizePhotoMode, setCustomizePhotoMode] = useState(false);

    const frontalRef = useRef<CardHandleInterface>(null);
    const traseraRef = useRef<CardHandleInterface>(null);

    const handleBase64GeneratedQR = (base64String: string) => {
        setBase64QR(base64String);
    };

    const handleUpdateFrontData = useCallback((value: any, key: string) => {
        setPayloadFrontCard((current) => {
            const data: any = { ...current }

            if (data[key] !== value) {
                data[key] = value;
            }

            if (key === "matricula") {
                data.codebar.value = value.value
            }

            return data;
        });
    }, []);

    const handleUpdateBackData = useCallback((value: any, key: string) => {
        setPayloadBackCard((current) => {
            const data: any = { ...current }
            data[key].value = value;
            return data
        });
    }, []);

    const handleOnRenderCodebar = (base64Image: string) => {
        setCodebar(base64Image);
    };

    const handleSelectPhotoID = (image?: string | undefined) => {
        if (image) {
            setPhoto(image);
            setCustomizePhotoMode(false)
        }
    };

    const handleCancelSelectPhoto = () => {
        setPhoto(undefined);
    };

    const handlePrintID = () => printCard(frontalRef.current?.getBase64Image(), traseraRef.current?.getBase64Image())

    const ToolbarStepper = () => (
        <div className="controls">
            <button className="print-id" onClick={handlePrintID}>
                Imprimir Credencial
            </button>
        </div>
    );

    const setAjustPhotoMode = () => {
        setCustomizePhotoMode(true)
    }

    const validationFullPath = (payloadFrontCard?.cardID && payloadFrontCard?.matricula) ? `${VALIDATION_URL}/${payloadFrontCard.cardID.value}/${payloadFrontCard.matricula.value}` : "";

    const items = [
        {
            title: "Información Principal",
            subtitle: "Datos importantes del solicitante",
            description: "Datos de la seccion frontal",
            hint: "No olvides verificar los datos ingresados",
            content: (
                <div className="contentCardBlock">
                    <div className="contentCardBlockLeft">
                        <div className="formIDData">
                            <InputXYCardMaker name="idNumber" label="ID/No Credencial [YY-0000]" type="text" value={payloadFrontCard.cardID?.value} observer={(data) => { handleUpdateFrontData(data, "cardID") }} withY required readOnly />
                            <InputXYCardMaker name="type" label="Tipo de ID" type="text" value={payloadFrontCard.tipo?.value} observer={(data) => { handleUpdateFrontData(data, "tipo") }} withY required />
                            <InputXYCardMaker name="name" label="Nombre" type="text" value={payloadFrontCard.nombre?.value} observer={(data) => { handleUpdateFrontData(data, "nombre") }} withX required />
                            <InputXYCardMaker name="subname" label="Segunda Linea Nombre" type="text" value={""} observer={(data) => { handleUpdateFrontData(data, "subnombre") }} withX required />
                            <InputXYCardMaker name="enrollment" label="Matricula" type="text" value={payloadFrontCard.matricula?.value} observer={(data) => { handleUpdateFrontData(data, "matricula") }} withX required />
                            <InputXYCardMaker name="dni" label="Carrera" type="text" value={payloadFrontCard.carrera?.value} observer={(data) => { handleUpdateFrontData(data, "carrera") }} withX required />
                            <div className="codebarContainer">
                                <label htmlFor="barcode">Código de barras</label>
                                <BarcodeCanvas id="barcode" value={(payloadFrontCard.codebar?.value) + ""} onRender={handleOnRenderCodebar} downloadable />
                            </div>
                            <div className="QRContainer">
                                <label htmlFor="barcode">Código QR de Verificacion</label>
                                {
                                    (payloadFrontCard.matricula && payloadFrontCard.cardID) &&
                                    <QRCodeGenerator type="url" url={validationFullPath} onBase64Generated={handleBase64GeneratedQR} size={1024} previewSize={100} downloadable />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: "Fotografía",
            subtitle: "Modifica la fotografía según lo que sea necesario",
            description: "La fotografía permite identificar visualmente al solicitante de la credencial",
            hint: "Verifica y ajusta la imagen segun lo necesario",
            content: (
                <div className="contentCardBlock">
                    <div className="contentCardBlockLeft">
                        {
                            photo && !customizePhotoMode ?
                                <div className="photoContainer genericContainer">
                                    <img src={photo} alt="Descripción de la imagen" />
                                    <button className="generic-button edit mb-2" onClick={() => setPhoto(undefined)}>Reemplazar</button>
                                    <button className="generic-button edit" onClick={() => setAjustPhotoMode()}>Ajustar</button>
                                </div>
                                :
                                <div className="photoContainer">
                                    <PictureIdComponent onCancel={handleCancelSelectPhoto} onConfirm={handleSelectPhotoID} image={photo} darkMode ajustMode={customizePhotoMode} />
                                </div>
                        }
                    </div>
                </div>
            )
        },
        {
            title: "Información de Contácto",
            subtitle: "Datos importantes del solicitante",
            description: "Datos de la sección Trasera",
            hint: "No olvides verificar los datos ingresados",
            content: (
                <div className="contentCardBlock">
                    <div className="contentCardBlockLeft">
                        <div className="formIDData ContactSectionData">
                            <InputCardMaker name="dni" label="DNI/CURP" type="text" value={payloadBackCard.curp?.value} observer={(data) => { handleUpdateBackData(data, "curp") }} required />
                            <InputCardMaker name="personal_phone" label="Télefono" type="phone" value={payloadBackCard.telefono?.value} observer={(data) => { handleUpdateBackData(data, "telefono") }} required />
                            <InputCardMaker name="email" label="Correo Electrónico" type="email" value={payloadBackCard.correo?.value} observer={(data) => { handleUpdateBackData(data, "correo") }} required />
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: "Información Domiciliaria",
            subtitle: "Datos importantes del solicitante",
            description: "Datos de la sección Trasera",
            hint: "No olvides verificar los datos ingresados",
            content: (
                <div className="contentCardBlock">
                    <div className="contentCardBlockLeft">
                        <div className="formIDData AddressSectionData">
                            <InputCardMaker name="home_address" label="Dirección" type="text" value={payloadBackCard.direccion?.value} observer={(data) => { handleUpdateBackData(data, "direccion") }} required />
                            <InputCardMaker name="city_address" label="Ciudad" type="text" value={payloadBackCard.ciudad?.value} observer={(data) => { handleUpdateBackData(data, "ciudad") }} required />
                            <InputCardMaker name="state_address" label="Estado" type="text" value={payloadBackCard.estado?.value} observer={(data) => { handleUpdateBackData(data, "estado") }} required />
                            <InputCardMaker name="cp_address" label="Código Postal" type="text" value={payloadBackCard.cp?.value} observer={(data) => { handleUpdateBackData(data, "cp") }} required />
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: "Información de seguridad",
            subtitle: "Datos importantes del solicitante",
            description: "No olvides verificar los datos ingresados",
            content: (
                <div className="contentCardBlock">
                    <div className="contentCardBlockLeft">
                        <div className="formIDData">
                            <InputCardMaker name="emergency_phone" label="Telefono de Emergencia" type="phone" value={payloadBackCard.telefono_emergencia?.value} observer={(data) => { handleUpdateBackData(data, "telefono_emergencia") }} required />
                            <InputCardMaker name="emergency_contact_name" label="Nombre/Contacto de Emergencia" type="text" value={payloadBackCard.nombre_emergencia?.value} observer={(data) => { handleUpdateBackData(data, "nombre_emergencia") }} required />
                            <InputCardMaker name="nss" label="Numero del Seguro Social" type="text" value={payloadBackCard.nss?.value} observer={(data) => { handleUpdateBackData(data, "nss") }} required />
                            <InputCardMaker name="bloodType" label="Tipo de Sangre" type="text" value={payloadBackCard.sangre?.value} observer={(data) => { handleUpdateBackData(data, "sangre") }} required />
                            <InputCardMaker name="isDonor" label="¿Es donador de organos?" type="text" value={payloadBackCard.donador?.value} observer={(data) => { handleUpdateBackData(data, "donador") }} required />
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: "Finalizar",
            subtitle: "Prevista final de la credencial",
            description: "Verifica todos los datos antes de confirmar la emision de la credencial",
            content: (
                <div className="endStepButtonPrint">
                    <ToolbarStepper />
                </div>
            )
        },
    ];

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "F12") {
                event.preventDefault();
                handlePrintID();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <div className="IDSContainer">
            <FormStepper title="ID Generator" subtitle="By. M.I.T.I Rene Corona Valdes" onFinish={onFinish} items={items} sideContent={
                <div className="PreviewRenderBlock">
                    <div className="containerRenderFront">
                        <FrontalCard ref={frontalRef} data={payloadFrontCard} photo={photo} codebar={codebar} refrendoBlocks={refrendoBlocks} qrCode={base64QR} />
                    </div>
                    <div className="containerRenderBack">
                        <TraseraCard ref={traseraRef} data={payloadBackCard} codebar={codebar} refrendoBlocks={refrendoBlocks} validationURL={validationFullPath} />
                    </div>
                </div>
            } />
        </div>
    );
};

export default IDCardMaker;
