import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableColumnModel from '@models/table.column';
import Table, { TableHeaderButton } from '@components/ui/Table';
import { faArrowsRotate, faCopy, faEye, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import useActionInProcess from '@hooks/useActionInProcess';
import useIdCardService from '@services/idcard.service';
import ResponseDataPaginated from '@models/response.data';
import useSwal from '@hooks/useSwal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useWindowDataPreview from '@hooks/useDataPreview.hook';
import { useNavigate } from 'react-router-dom';

/**
 * IDCardsSearch properties interface
 */
interface IDCardsSearchProperties {
    setViewTitle?: (title: string) => void;
}

const TableColumns: Array<TableColumnModel> = [
    new TableColumnModel("ID Card", "card_id"),
    new TableColumnModel("Nombre", "nombre"),
    new TableColumnModel("Carrera", "carrera"),
    new TableColumnModel("Correo Electrónico", "correo"),
    new TableColumnModel("Curp", "curp"),
    new TableColumnModel("¿Es donador?", "donador"),
    new TableColumnModel("Estado", "estado"),
    new TableColumnModel("Matricula", "matricula"),
    new TableColumnModel("Telefono", "telefono"),
    new TableColumnModel("Nombre de emergencia", "nombre_emergencia"),
    new TableColumnModel("Teléfono de emergencia", "telefono_emergencia"),
    new TableColumnModel("Tipo", "tipo"),
    new TableColumnModel("NSS", "nss"),
    new TableColumnModel("Tipo de Sangre", "sangre"),
];

interface HeaderOptionsInterface {
    onSearch: (page?: number, querySearch?: string) => void;
    onToggleCopyMode: () => void;
    copyMode: boolean
    rowsDirection: 'asc' | 'desc'
    setDirection: (current: any) => void;
}

const HeaderOptions = memo(({ rowsDirection, setDirection, onSearch, onToggleCopyMode, copyMode }: HeaderOptionsInterface) => <div className="flex flex-row justify-center items-center gap-4">
    <TableHeaderButton icon={faArrowsRotate} onClick={() => onSearch()} title="Limpiar búsquedas" />
    <TableHeaderButton icon={rowsDirection !== 'asc' ? faSortDown : faSortUp} onClick={() => setDirection((current: string) => current === "asc" ? "desc" : "asc")} title="Set direction" />
    <TableHeaderButton
        icon={faCopy}
        onClick={onToggleCopyMode}
        classes={copyMode ? "bg-blue-700 hover:bg-blue-500 active:bg-blue-900" : ''}
        title="Modo de captura a portapapeles" />
</div>);

interface SearchMetadata {
    filter: string
    querySearch: string,
    page: number,
    rowsDirection: 'asc' | 'desc'
}

/**
 * IDCardsSearch component
 * @param {IDCardsSearchProperties} props - Component props
 * @returns {JSX.Element}
 */
const IDCardsSearch: React.FC<IDCardsSearchProperties> = ({ setViewTitle }): JSX.Element => {
    const { alert } = useSwal();
    const navigate = useNavigate();
    const { search } = useIdCardService();
    const { wait, hideWait, showWait } = useActionInProcess();
    const { userDataPreview } = useWindowDataPreview()
    const [filter, setFilter] = useState(TableColumns[0]);
    const [rowSet, setRowSet] = useState<Array<any> | null>(null);
    const [copyMode, setCopyMode] = useState(false);
    const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
    const [metadata, setMetadata] = useState<ResponseDataPaginated>();
    const [lastSearchMetadata, setLastSearchMetadata] = useState<SearchMetadata | undefined>(undefined);

    const handleSearch = useCallback((page: number = 1, querySearch: string = "") => {
        if (!querySearch && lastSearchMetadata && page === lastSearchMetadata.page) {
            querySearch = lastSearchMetadata.querySearch
            page = lastSearchMetadata.page
        }
        if (!wait) {
            showWait("Obteniendo los registros de usuarios")
            search(filter?.value, querySearch, page, 8, filter?.value, direction).then((response: any) => {
                setLastSearchMetadata({
                    filter: filter?.value,
                    querySearch,
                    page,
                    rowsDirection: direction
                });
                const status: number = response.status;
                const result: ResponseDataPaginated = response.data;
                const data: Array<any> = result.data;                
                setMetadata(result);
                
                if (status === 200 && data && data.length > 0) {
                    const rows = data.map((item: any, key: number) => {
                        return {
                            ...item,
                            id: key,
                            status: item.status,
                        }
                    });
                    setRowSet(rows);
                    hideWait();
                    return
                } else {
                    setRowSet([])
                    hideWait();
                    alert("info", "No hay resultados disponibles en el sistema", 1000, true)
                    return
                }
            }).catch((err: any) => {
                let { status } = err;
                if (status === 404) {
                    hideWait()
                    setRowSet([])
                    alert("info", "No hay información en el sistema, posiblemente se debe a un error en el su consulta", 1000, true)
                    return
                }
                hideWait()
                alert("error", "Ocurrio un error al procesar su solicitud", 1000, true)
            });
        }
    }, [alert, filter, hideWait, search, showWait, wait, direction, lastSearchMetadata]);

    const handlePageChange = (page: number = 1, query: string = "") => {
        handleSearch(page, query)
    }

    const handleSearchQuery = (payload: any) => {
        handleSearch(1, payload)
    }

    const handleToggleCopyMode = () => {
        setCopyMode((state) => {
            return !state;
        })
    }

    const handleShowIDData = (data: any) => {
        userDataPreview(data)
    }

    const handleGotoFullIDDataView = (data: any) => {
        // console.log("goto data", data);
        navigate(`/credenciales/information?id=${data.card_id}`);
    }

    const handleRenderColumnOptions = (data: any, key: number) => {
        return <div className="flex flex-row gap-2 justify-center items-center">
            <button className="text-white bg-teal-700 hover:bg-teal-600 active:bg-teal-900 w-8 h-8 rounded-full shadow-sm border-teal-800 border-2" onClick={() => handleGotoFullIDDataView(data)} title="Ver información de la Credencial">
                <FontAwesomeIcon icon={faEye} className="text-white" />
            </button>
        </div>
    }

    const handleRowDoubleClick = (data: any) => handleGotoFullIDDataView(data);

    useEffect(()=>{
        if(lastSearchMetadata === undefined && !wait){
            handleSearch(1, "")
            setFilter(TableColumns[1])
        }
    }, [lastSearchMetadata, handleSearch, wait])

    return <div className="idcardssearch">
        <Table
            columns={TableColumns}
            headerOptions={<HeaderOptions rowsDirection={direction} setDirection={setDirection} onSearch={handleSearch} onToggleCopyMode={handleToggleCopyMode} copyMode={copyMode} />}
            metadata={metadata}
            rows={rowSet}
            onPageChange={handlePageChange}
            onHeaderSelect={(filter: any) => setFilter(filter)} filter={filter}
            onSearch={handleSearchQuery}
            onRenderOptions={handleRenderColumnOptions}
            onRowDoubleClick={handleRowDoubleClick}
            onContextMenu={handleShowIDData}
            darkMode
            onCopyAction={handleToggleCopyMode}
            copiable={copyMode}
            searchAutofocus
        />
    </div>;
};

IDCardsSearch.propTypes = {
    setViewTitle: PropTypes.func,
};

IDCardsSearch.defaultProps = {
    setViewTitle: () => { },
};

export default IDCardsSearch;
