import React from 'react'
import './style.scss'

const SandClockAnimation = () => {
  return (
    <div className="animation-waiting">
        <div className="clock-top">
          <div className="a"></div>
        </div>
        <div className="clock-bottom">
          <div className="b"></div>
        </div>
    </div>
  )
}

export default SandClockAnimation
