import { useState, useEffect, useRef, useCallback } from "react";
import "./style.css";

interface FormStepperMenuOptionInterface {
    title?: string;
    subtitle?: string;
    active?: boolean,
    index: number,
    onAction?: (index: number) => void,
}

const FormStepperMenuOption = (props: FormStepperMenuOptionInterface) => {
    const { active, title, subtitle, index, onAction } = props;
    const handleAction = () => {
        if (onAction) {
            onAction(index);
        }
    }
    return (
        <div className={`menuOption ${active ? "active" : ""}`} onClick={handleAction}>
            <div className="blockLeft">
                <div className="title">{title}</div>
                <div className="subtitle">{subtitle}</div>
            </div>
            <div className={`blockRight ${active ? "active" : ""}`}>
                <p>{index + 1}</p>
            </div>
            <div className={`indicator ${active ? "active" : ""}`}></div>
        </div>
    );
}

interface FormStepperItems {
    title: string,
    subtitle: string,
    description?: string;
    hint?: string;
    content: JSX.Element | string
}

interface FormStepperProps {
    title?: string;
    subtitle?: string;
    items?: Array<FormStepperItems>;
    toolbar?: JSX.Element | string;
    sideContent?: React.ReactNode;
    onFinish?: () => void
}

const FormStepper = (props: FormStepperProps) => {
    const { title, subtitle, items, toolbar, sideContent, onFinish } = props;
    const [shouldShow, setShouldShow] = useState<boolean>(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [sideContentVisible, setSideContentVisible] = useState(true);
    const rightContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!shouldShow) {
            setTimeout(() => {
                setShouldShow(true);
            }, 50);
        }
    }, [shouldShow]);

    const scrollToTop = () => {
        // if (rightContentRef.current) {
        //     rightContentRef.current.scrollIntoView({ behavior: "smooth" });
        // }
        window.scrollTo(0, 0);
    }

    const handleClickStep = (index: number) => {
        if (currentIndex !== index) {
            setShouldShow(false);
            setCurrentIndex(index);
            scrollToTop();
        }
    }

    const handleNextStep = useCallback(() => {
        if (items && items.length >= 1 && currentIndex === items?.length - 1) return;
        setShouldShow(false);
        setCurrentIndex(prevIndex => prevIndex + 1);
        scrollToTop();
    }, [items, currentIndex]);

    const handleBackStep = useCallback(() => {
        if (currentIndex === 0) return;
        setShouldShow(false);
        setCurrentIndex(prevIndex => prevIndex - 1);
        scrollToTop();
    }, [currentIndex]);

    const toggleSideContent = useCallback(() => {
        setSideContentVisible(prevState => !prevState);
    }, []);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "PageDown") {
                event.preventDefault();
                handleNextStep();
            } else if (event.key === "PageUp") {
                event.preventDefault();
                handleBackStep();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleNextStep, handleBackStep]);

    return (
        <div className="FormStepper">
            <div className="FormStepperMain">
                <div className="titleSection">
                    {title && (
                        <div className="titlesBlock">
                            <div className="title">{title}</div>
                            {subtitle && <div className="subtitle">{subtitle}</div>}
                        </div>
                    )}
                    {toolbar}
                </div>
                <div className="mainSection">
                    <div className="left">
                        {items && items.map((subitem, index) => (
                            <FormStepperMenuOption
                                key={index}
                                active={index === currentIndex}
                                index={index}
                                title={subitem.title}
                                subtitle={subitem.subtitle}
                                onAction={handleClickStep}
                            />
                        ))}
                    </div>
                    <div className="right" ref={rightContentRef}>
                        <div className="header">
                            <div className="titlesSection">
                                {items && items.length > 0 && <p className="step">Paso <b>{currentIndex + 1}</b> de <b>{items.length}</b></p>}
                                {items && items[currentIndex] && <p className="title">{items[currentIndex].description}</p>}
                                {items && items[currentIndex] && <p className="hint">{items[currentIndex].hint}</p>}
                            </div>
                            <div className="actions">
                                {items && items.length >= 1 && currentIndex === items?.length - 1 ?
                                    <button className="steps-action-button" onClick={() => setCurrentIndex(0)}>Inicio</button>
                                    :
                                    <button className="steps-action-button" onClick={handleNextStep}>Continuar</button>
                                }
                                {
                                    onFinish && <button className="steps-action-button" style={{backgroundColor: "#8d0000", marginLeft: "10px"}} onClick={onFinish}>Finalizar</button>
                                }
                            </div>
                        </div>
                        <div className="components">
                            <button className={`toggleButton ${sideContentVisible ? "" : ""}`} onClick={toggleSideContent}>
                                {sideContentVisible ? "Ocultar Preview" : "MostrarPreview"}
                            </button>
                            <div className={`children ${sideContentVisible ? "" : "expanded"}`}>
                                {shouldShow && items && items[currentIndex].content}
                            </div>
                            <div className={`sideContent ${sideContentVisible ? "show" : "hidden"}`}>
                                {sideContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormStepper;
